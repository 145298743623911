import React, { useEffect, useState } from "react";
import { getTextColor, log } from "../utils/utils";
import { getLanguageComponent } from "../utils/language";
import PopupAD from "./PopupAD";
import DialogOpenMember from "./DialogOpenMember";
import {incentiveAD, incentiveAD_jumpToRecharge} from "../utils/drawUtils";
import { LOCATION } from "../service/config";

interface IProps {
  initValue: number;
  setValue: Function;
  reset: boolean;
}

export enum qualityEnum {
  标准 = 1,
  更多细节 = 2,
}

const Quality: React.FC<IProps> = (props) => {
  const { initValue, setValue, reset } = props;
  const lang = getLanguageComponent();
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });
  const [locks, setLocks] = useState({
    2: false,
  });
  useEffect(() => {
    if (reset) {
      console.log("页面已跳转,Quality,状态重置");
      setLocks({ 2: false });
    }
  }, [reset]);
  const changeHandler = async (index: qualityEnum.更多细节) => {
    if (locks[index]) {
      setValue(index);
    } else {
      await incentiveAD_jumpToRecharge(
        () => callback(index),
        setShowAdBtn,
        setIsShowPopupAD,
        setAdEndCallback,
        setIsShowMember,
        LOCATION.ADVANCED_DRAW_SETTING
      );
    }
  };

  const callback = (n: qualityEnum) => {
    setValue(n);
    setLocks((prevLocks) => ({ ...prevLocks, [n]: true }));
  };
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/4.png')]"></div>
        {lang.质量}
      </div>
      <div className="item-body-rounded">
        <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
          <div
            onClick={() => {
              log("quality_click", { value: qualityEnum.标准 });
              setValue(qualityEnum.标准);
            }}
            className={
              "option " +
              (initValue === qualityEnum.标准
                ? "option-active " + getTextColor()
                : "")
            }
          >
            {lang.标准}
          </div>
          <div
            onClick={() => {
              log("quality_click", { value: qualityEnum.更多细节 });
              changeHandler(qualityEnum.更多细节);
            }}
            className={
              "option " +
              (initValue === qualityEnum.更多细节
                ? "option-active " + getTextColor()
                : "")
            }
          >
            {lang.更多细节}
            <div className="z"></div>
          </div>
        </div>
      </div>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to unlock limit"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </>
  );
};

export default Quality;
