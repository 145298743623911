import { post, postToken } from "./axios";

export const createPost = (data: any) => {
  return post("/space/post/create", data);
};
export const fateCreate = (data: any) => {
  return post("/space/ai/fate/create", data);
};
export const getProfile = (data: any) => {
  return post("/user/center/profile", data);
};
export const externalLogin = (data: any) => {
  return post("/user/center/external/login/do", data);
};
export const getLangOffiaccountMdseList = (data: any) => {
  return post("/x/account/ai/mdse/lang/list", data);
};
export const getLangOffiaccountMdseListMj = (data: any) => {
  return post("/x/account/ai/mdse/mj/list", data);
};
export const orderApplyJsapi = (data: any, token: string) => {
  return postToken("/x/account/ai/pay/apply/jsapi", data, token);
};
export const getStyleListApi = (data: any) => {
  return post("/ai/graph/style/list", data);
};
export const ossAuthApi = (data: any) => {
  return post("/assistant/oss/auth", data);
};
export const createGraphApi = (data: any) => {
  return post("/ai/graph/create", data);
};
export const queryGraphApi = (data: any) => {
  return post("/ai/graph/query", data);
};
export const getArtistList = (data: any) => {
  return post("/ai/graph/artist/list", data);
};
export const detail = (data: any) => {
  return post("/ai/graph/detail", data);
};
export const getSuggestedPrompts = (data: any) => {
  return post("/ai/graph/prompt/suggested", data);
};
export const remove = (data: any) => {
  return post("/ai/graph/remove", data);
};
export const getAiUsers = (data: any) => {
  return post("/ai/chat/ai-user/list", data);
};
export const action = (data: any) => {
  return post("/ai/graph/action", data);
};
export const galleryDetail = (data: any) => {
  return post("/ai/graph/gallery/detail", data);
};
export const styleTabs = (data: any) => {
  return post("/ai/graph/style/tabs", data);
};
export const avatarSamples = (data: any) => {
  return post("/ai/graph/avatar/samples", data);
};
export const creatable = (data: { type: number; prompt?: string }) => {
  return post("/ai/graph/creatable", data);
};
export const adAvailable = (data: { location: number }) => {
  return post("/user/center/ad/available", data);
};
export const getOrderList = (data: { pageInfo: string }) => {
  return post("/account/pay/ai/order/list", data);
};
export const getCoinStream = (data: { pageInfo: string }) => {
  return post("/account/pay/ai/credit/list", data);
};
export const nsfwIgnore = (data: { days: number }) => {
  return post("/ai/graph/nsfw/ignore", data);
};
export const redrawSamples = (data: any) => {
  return post("/ai/graph/redraw/samples", data);
};
export const maskPredict = (data: any) => {
  return post("/ai/graph/dress-up/predict", data);
};
export const predictAuto = (data: any) => {
  return post("/ai/graph/dress-up/predict/auto", data);
};
export const getDrawConfig = (data: any) => {
  return post("/ai/graph/draw/config", data);
};

export const clientEvent = (data: { name: string; data: string }) => {
  return post("/assistant/client/event", data);
};
export const creatableGift = (data: { activity: string; }) => {
  return post("/user/center/activity/creatable/gift", data);
};

export const crossAppInivtationCode = () => {
  return post("/user/center/invitation-code/cross-app", {});
};