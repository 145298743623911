import { commonInterface, isShowInput, isVip, log } from "./utils";
import { adAvailable } from "../service/api";

export const isInApp = () => {
  if (window.navigator.userAgent.match("appName/")) {
    return true;
  }
  return false;
};

export const waitFor = (delay: any) =>
  new Promise((resolve) => setTimeout(resolve, delay));
export const nativeUploadHandler = () => {
  console.info("commonInterface.uploadImage");
  commonInterface("uploadImage");
};
export const nativeUploadHDImageHandler = () => {
  commonInterface("uploadHDImage");
};
export const nativeUploadAvatarHandler = () => {
  isShowInput("uploadAvatar")
    ? commonInterface("uploadAvatar")
    : commonInterface("uploadImage");
};
export const getStyleBoxWidth = (len: any) => {
  switch (len) {
    case 3:
    case 4:
      return 172 + "px";
      break;
    case 5:
    case 6:
      return 264 + "px";
    default:
      return "auto";
  }
};

export const incentiveAD = async (
  doFunc: Function,
  setShowAdBtn: any,
  setIsShowPopupAD: any,
  setAdEndCallback: any,
  setIsShowMember: any,
  location: number
) => {
  const vip = await isVip();
  if (vip) {
    console.info("直接。call back");
    doFunc();
  } else {
    const res: any = await adAvailable({
      location,
    });
    if (isShowInput("ad") && res.data.status !== 0) {
      setShowAdBtn(res.data.status === 1);
      setIsShowPopupAD(true);
      console.info("看完之后。call back");
      setAdEndCallback(() => doFunc);
    } else {
      setIsShowMember(true);
    }
  }
};
export const incentiveAD_jumpToRecharge = async (
  doFunc: Function,
  setShowAdBtn: any,
  setIsShowPopupAD: any,
  setAdEndCallback: any,
  setIsShowMember: any,
  location: number
) => {
  const vip = await isVip();
  if (vip) {
    doFunc();
  } else {
    commonInterface("jumpToRecharge");
  }
};
window.uploadCallbacks = {};
window.uploadImageResult = (
  url: string,
  width: any,
  height: any,
  size: any
) => {
  window.uploadCallbacks[window.identifier](url, width, height, size);
};
