import React, { useEffect, useState } from "react";
import { getBgColor, getBorderColor, isVip, log } from "../utils/utils";

import DialogOpenMember from "./DialogOpenMember";
import { nativeUploadHandler } from "../utils/drawUtils";
import { Popup } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";

interface IProps {
  initValue: string;
  setValue: Function;
  faceImageSamples: string[];
}

const identifier = "faceImage";
const FaceImage: React.FC<IProps> = (props) => {
  const { initValue, setValue, faceImageSamples } = props;
  const lang = getLanguageComponent();
  const [isShowMember, setIsShowMember] = useState(false);
  const [url, setUrl] = useState("");
  const [isShowHelp, setIsShowHelp] = useState(false);
  useEffect(() => {
    window.uploadCallbacks[identifier] = (url: string) => {
      setUrl(url);
      setValue(url);
    };

    return () => {
      delete window.uploadCallbacks[identifier];
    };
  }, []);

  const uploadImageHandler = () => {
    isVip().then((res) => {
      if (res) {
        nativeUploadHandler();
      } else {
        setIsShowMember(true);
      }
    });
  };
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/7.webp')]"></div>
        {lang["Looks like"]}
        <span className="text-card font-normal mx-0.33 text-1">
          ({lang["Optional"]})
        </span>
        <div
          onClick={() => setIsShowHelp(true)}
          className="bg-[url('../assets/images/help.png')] w-1.67 h-1.67 bg-contain"
        ></div>
      </div>
      <div className="item-body-rounded">
        <div className="flex justify-between items-center">
          <div className="w-4.17 h-4.17 flex items-center justify-center bg-bg rounded-50 relative">
            {url !== "" ? (
              <>
                <div
                  className={`w-4.17 h-4.17 bg-no-repeat bg-center rounded-50 bg-contain ${
                    initValue === url ? getBorderColor() + " border-0.17" : ""
                  }`}
                  style={{ backgroundImage: "url(" + url + ")" }}
                  onClick={() => setValue(url)}
                ></div>
                <img
                  className="w-1.33 h-1.33 right-f0.665 top-f0.665 absolute"
                  src={require("../assets/images/delete.png")}
                  onClick={() => {
                    log("remove_face_image_click");
                    setUrl("");
                    setValue("");
                  }}
                />
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    window.identifier = "faceImage";
                    uploadImageHandler();
                  }}
                  className="bg-[url('../assets/images/add_2.png')] w-1.33 h-1.33 bg-contain"
                ></div>
                <div className="z w-1.67 h-1.67"></div>
              </>
            )}
          </div>
          {faceImageSamples.map((item, index) => {
            return (
              <div
                key={index}
                className={`w-4.17 h-4.17 rounded-50 ${
                  initValue === item ? getBorderColor() + " border-0.17" : ""
                }`}
                onClick={() => {
                  setValue(item === initValue ? "" : item);
                }}
              >
                <img src={item} className="rounded-50" />
              </div>
            );
          })}
        </div>
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
      <Popup
        visible={isShowHelp}
        onMaskClick={() => {
          setIsShowHelp(false);
        }}
        onClose={() => {
          setIsShowHelp(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "1.67rem",
          borderTopRightRadius: "1.67rem",
          backgroundColor: "rgba(246, 247, 249, 1)",
        }}
      >
        <div className="py-2.5 px-1.33 flex items-center flex-col text-center">
          <div className="text-1.67 text-34 font-bold mb-1">
            {lang["How to make a quality creation"]}
          </div>
          <div className="text-34 text-opacity-80 mb-1.33">
            {lang["Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation."]}
          </div>
          <div className="bg-[url('../assets/images/face_image_help.webp')] w-25.58 h-21.5 bg-contain mb-1.67 bg-no-repeat"></div>
          <div
            onClick={() => setIsShowHelp(false)}
            className={`rounded-2.33 p-1.17 self-stretch text-center ${getBgColor()}`}
          >
            <div className="text-white font-bold text-1.67">{lang["OK,I got it!"]}</div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default FaceImage;
