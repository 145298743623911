import React from "react";
import { Dialog } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";
import {
  commonInterface,
  getMemberBtnImage,
  getMemberImage,
} from "../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  cancelHandler: Function;
}

const lang = getLanguageComponent();
const DialogOpenMember: React.FC<IProps> = (props) => {
  const { isShow, hideDialog, cancelHandler } = props;
  const jumpToRecharge = () => {
    commonInterface("jumpToRecharge");
    hideDialog();
    //跳转到充值页
  };
  return (
    <Dialog
      visible={isShow}
      closeOnMaskClick={true}
      bodyClassName="bg-transparent"
      content={
        <div
          className={
            getMemberImage() +
            " w-22.58 h-26.67 bg-contain bg-no-repeat flex flex-col items-center relative"
          }
        >
          <div className="mt-7.17 text-white text-1.67 font-bold mb-1 text-center">
            {lang.您还暂未开通会员}
          </div>
          <div
            className="text-card text-1.17 px-1.33"
            dangerouslySetInnerHTML={{ __html: lang.开通会员描述 }}
          ></div>
          <div
            onClick={jumpToRecharge}
            className={
              getMemberBtnImage() +
              " absolute bottom-2.5 mx-auto leading-4 bg-contain bg-no-repeat w-17.58 h-4 text-white text-center"
            }
          >
            {lang.立即开通}
          </div>
        </div>
      }
      closeOnAction
      onClose={() => cancelHandler()}
    ></Dialog>
  );
};

export default DialogOpenMember;
