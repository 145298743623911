import React, { useEffect, useState } from "react";
import { getBgColor, isVip, log } from "../utils/utils";
import { Popup, Slider } from "antd-mobile";
import { nativeUploadHandler } from "../utils/drawUtils";

import DialogOpenMember from "./DialogOpenMember";
import { getLanguageComponent } from "../utils/language";

interface IProps {
  initValue: string;
  setValue: Function;
  setInputImageWidth: Function;
  setInputImageHeight: Function;
  setInputImageSize: Function;
  similarity: number;
  defaultSimilarity: number;
  setSimilarity: Function;
}

const identifier = "openpose";
const UploadImage: React.FC<IProps> = (props) => {
  const {
    initValue,
    setValue,
    setInputImageWidth,
    setInputImageHeight,
    setInputImageSize,
    similarity,
    defaultSimilarity,
    setSimilarity,
  } = props;
  const lang = getLanguageComponent();
  const [isShowMember, setIsShowMember] = useState(false);
  const [vip, setVip] = useState(false);
  const [isShowHelp, setIsShowHelp] = useState(false);
  useEffect(() => {
    isVip().then((res) => {
      setVip(res);
    });
    window.uploadCallbacks[identifier] = (
      url: string,
      width: number,
      height: number,
      size: number
    ) => {
      setValue(url);
      setInputImageWidth(width);
      setInputImageHeight(height);
      setInputImageSize(size);
    };

    return () => {
      delete window.uploadCallbacks[identifier];
    };
  }, []);
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/5.png')]"></div>
        {lang["Reference image"]}
        <span className="text-card font-normal mx-0.33 text-1">
          ({lang["Optional"]})
        </span>
        <div
          onClick={() => setIsShowHelp(true)}
          className="bg-[url('../assets/images/help.png')] w-1.67 h-1.67 bg-contain"
        ></div>
      </div>
      <div
        className="item-body-rounded  pt-1.83 pb-0"
        style={{
          paddingBottom: 12,
        }}
      >
        <div className="flex">
          <div className="relative bg-bg h-5.17 w-5.17 rounded-0.83 border-201 mr-1 border-0.08 border-dashed flex justify-center items-center text-201 text-3">
            {initValue !== "" ? (
              <>
                <div
                  className="h-5.17 w-5.17 bg-no-repeat bg-center rounded-0.83 bg-contain"
                  style={{ backgroundImage: "url(" + initValue + ")" }}
                ></div>
                <img
                  className="w-1.33 h-1.33 right-f0.665 top-f0.665 absolute"
                  src={require("../assets/images/delete.png")}
                  onClick={() => {
                    log("remove_upload_image_click");
                    setValue("");
                    setSimilarity(defaultSimilarity);
                    setInputImageWidth(undefined);
                    setInputImageHeight(undefined);
                    setInputImageSize(undefined);
                  }}
                />
              </>
            ) : (
              <img
                className="w-2"
                onClick={() => {
                  window.identifier = "openpose";
                  nativeUploadHandler();
                }}
                src={require("../assets/images/add.png")}
              />
            )}
          </div>
          <div className="flex-1">
            <div className="font-bold text-34 flex items-center">
              {lang["Image similarity"]}
              <div className="bg-[url('../assets/images/z.png')] w-1.33 h-1.33 ml-0.33 bg-contain"></div>
            </div>
            <div className="text-card text-1">
              {lang["Higher parameter,closer to original image."]}
            </div>
            <div className="flex items-center">
              <Slider
                className={`denoisingStrength-slider flex-1 ${
                  vip ? "" : "disabled"
                }`}
                value={similarity}
                min={0}
                max={1}
                step={0.01}
                onChange={(value) => {
                  if (!vip) {
                    setIsShowMember(true);
                  } else {
                    setSimilarity(value);
                  }
                }}
                icon={<div></div>}
                onAfterChange={(value) => {
                  if (!vip) {
                    return;
                  }
                  setSimilarity(value);
                }}
              />
              <div className="text-card">{(similarity * 100) >> 0}%</div>
            </div>
          </div>
        </div>
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
      <Popup
        visible={isShowHelp}
        onMaskClick={() => {
          setIsShowHelp(false);
        }}
        onClose={() => {
          setIsShowHelp(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "1.67rem",
          borderTopRightRadius: "1.67rem",
          backgroundColor: "rgba(246, 247, 249, 1)",
        }}
      >
        <div className="py-2.5 px-1.33 flex items-center flex-col text-center">
          <div className="text-1.67 text-34 font-bold mb-1">
            {lang["Pose Matching to a Reference Image"]}
          </div>
          <div className="text-34 text-opacity-80 mb-1.33">
            {
              lang[
                "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image."
              ]
            }
          </div>
          <div className="bg-[url('../assets/images/openpose_image_help.webp')] w-21.5 h-21.5 bg-contain mb-1.67"></div>
          <div
            onClick={() => setIsShowHelp(false)}
            className={`rounded-2.33 p-1.17 self-stretch text-center ${getBgColor()}`}
          >
            <div className="text-white font-bold text-1.67">
              {lang["OK,I got it!"]}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default UploadImage;
