export default {
  lang: "it",
  AI绘画: "AiLady",
  输入描述语: "Inserisci Prompt",
  随机一个: "Ispirazione",
  清空文本: "Cancella prompt",
  选择风格: "Scegli Stile",
  选择比例: "Rapporto d'Aspetto",
  上传参考图: "Carica Immagine",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "L'immagine selezionata sarà utilizzata come riferimento per il risultato finale",
  质量: "Qualità",
  标准: "Standard",
  更多细节: "Più dettagli",
  描述词相关度: "Scala CFG",
  低: "Basso",
  中: "Medio",
  高: "Alto",
  友情提醒:
    "Promemoria amichevole: Si prega di rispettare gli standard di creazione delle immagini e di vietare l'invio di contenuti illegali. I contenuti illegali possono essere intercettati dal sistema e anche il tuo account potrebbe essere bannato.",
  生成画作: "Genera",
  "AI绘画发起中...": "Inizio Analisi...",
  "AI绘画解析中...": "Aggiunta dettagli...",
  "AI绘画生成中...": "Generazione in corso...",
  无可清空内容: "Niente da cancellare...",
  请输入描述语: "Inserisci prima il prompt",
  提示: "Avviso",
  确认删除该画作: "Sei sicuro di volerlo cancellare ora?",
  取消: "Annulla",
  确定: "Ok",
  图片举报成功感谢你的反馈: "Segnalazione inviata con successo",
  描: "",
  述: "",
  词: "Prompt",
  选: "",
  择: "",
  风: "",
  格: "Stile",
  比: "",
  例: "Rapporto",
  质: "",
  量: "Qualità",
  参: "",
  考: "",
  图: "Immagine",
  相: "",
  似: "",
  度: "CFG",
  重绘: "Ricrea",
  删除: "Cancella",
  下载: "Scarica",
  喜欢: "Mi piace",
  请检查网络: "Controlla la connessione di rete",
  您还暂未开通会员: "Unisciti a Pro",
  立即开通: "Continua",
  开通会员描述:
    "Goditi creatività AI illimitata e un'esperienza avanzata di pittura",
  历史记录: "Storico",
  收藏: "Segna",
  智能替换: "Rimuovi Oggetto",
  请您先在图片上标记想要替换的元素:
    "Si prega di contrassegnare prima gli elementi che si desidera sostituire nell'immagine～",
  选择替换区域: "Seleziona area di sostituzione",
  快速选择: "Taglia tutto",
  笔刷: "Pennello",
  橡皮擦: "Gomma",
  图片分区智能识别中: "Inizia l'elaborazione",
  开始下载: "Inizio download...",
  "Reference image": "Immagine di Posizione",
  Optional: "Opzionale",
  "Image similarity": "Somiglianza dell'immagine",
  "Higher parameter,closer to original image.":
    "Parametro più alto, più vicino all'immagine originale.",
  "Looks like": "Abbinamento del Viso",
  "Pose Matching to a Reference Image": "Abbinamento della posa a un'immagine di riferimento",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Fornisci semplicemente una foto di riferimento e la nostra tecnologia replicherà senza sforzo la sua posa in una nuova immagine.",
  "OK,I got it!": "OK, ho capito!",
  "How to make a quality creation": "Come realizzare una creazione di qualità",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Si prega di caricare foto con viso chiaro e frontale. Una foto di cattiva qualità porterà a una cattiva creazione.",
  Credit: "Credito",
  Credits: "Crediti",
  Buy: "Acquista",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "I tuoi crediti non sono sufficienti per utilizzare questa funzione. Si prega di visitare il centro acquisti per comprare più crediti",
  "Upgrade to Pro ?": "Passare a Pro?",
  "Or continue without Pro library": "O continua senza la libreria Pro",
  Upgrade: "Aggiorna",
  "The creation may contain NSFW content.":
    "La creazione potrebbe contenere contenuti NSFW.",
  "Don't ask again for 30 days": "Non chiedere ancora per 30 giorni",
  "I understand and wish to proceed": "Capisco e desidero procedere",
  "Watch ad to get free trial!": "Guarda l'annuncio per ottenere la prova gratuita!",
  "Upload photo": "Carica foto",
  Payments: "Pagamenti",
  "No data available": "Nessun dato disponibile",
  "Subscribe to unlock limit": "Iscriviti per sbloccare il limite",
  "Watch ad to create": "Guarda l'annuncio per creare",
  ClaimSuccess: "Tutto a posto! ✅ Abbiamo salvato i tuoi crediti per domani. Assicurati di richiederli prima che scompaiano!",
  Magic:"La magia sta fermentando... 🧙‍"
};