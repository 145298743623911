export default {
  lang: "en",
  AI绘画: "Vẽ bằng Trí tuệ Nhân tạo",
  输入描述语: "Mô tả đầu vào",
  随机一个: "Ngẫu nhiên một",
  清空文本: "Xóa Văn bản",
  选择风格: "Chọn một phong cách",
  选择比例: "Chọn tỷ lệ",
  上传参考图: "Tải lên hình ảnh tham khảo",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "Hãy để Trí tuệ Nhân tạo tạo ra theo mô tả dựa trên hình ảnh tham khảo",
  质量: "Chất lượng",
  标准: "Tiêu chuẩn",
  更多细节: "Thêm chi tiết",
  描述词相关度: "Tính liên quan của mô tả",
  低: "Thấp",
  中: "Trung bình",
  高: "Cao",
  友情提醒:
    'Lời nhắc thân thiện: Vui lòng tuân thủ các tiêu chuẩn tạo hình ảnh và cấm việc gửi nội dung vi phạm pháp luật. Nội dung vi phạm pháp luật có thể bị chặn bởi hệ thống và trong trường hợp nghiêm trọng, tài khoản của bạn có thể bị cấm. Vui lòng tham khảo "',
  社区规范: ' Tiêu chuẩn Cộng đồng" để biết thông số chi tiết',
  生成画作: "Tạo tác phẩm nghệ thuật",
  "AI绘画发起中...": "Bắt đầu vẽ bằng Trí tuệ Nhân tạo...",
  "AI绘画解析中...": "Phân tích vẽ bằng Trí tuệ Nhân tạo...",
  "AI绘画生成中...": "Tạo vẽ bằng Trí tuệ Nhân tạo...",
  无可清空内容: "Không có gì để xóa~",
  请输入描述语: "Vui lòng nhập mô tả",
  提示: "Gợi ý",
  确认删除该画作: "Bạn có chắc muốn xóa bức tranh này không?",
  取消: "Hủy",
  确定: "Đồng ý",
  图片举报成功感谢你的反馈:
    "Báo cáo hình ảnh thành công. Cảm ơn bạn đã phản hồi",
  描: "",
  述: "",
  词: "Từ Mô tả",
  选: "",
  择: "",
  风: "",
  格: "Phong Cách Đã Chọn",
  比: "",
  例: "Tỷ lệ Đã Chọn",
  质: "",
  量: "Chất lượng",
  参: "",
  考: "",
  图: "Hình Ảnh Tham Khảo",
  相: "",
  似: "",
  度: "Tương đồng",
  重绘: "Vẽ lại",
  删除: "Xóa",
  下载: "Tải xuống",
  喜欢: "Thích",
  请检查网络: "Vui lòng kiểm tra WEB",
  您还暂未开通会员: "Bạn chưa mở thành viên",
  立即开通: "Mở ngay",
  开通会员描述:
    "<span style='color:rgba(255, 181, 77, 1)'>$0.01</span>/ngày để thưởng thức các bức tranh AI không giới hạn",
  历史记录: "lịch sử",
  收藏:"Bộ sưu tập",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
