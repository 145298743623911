import React, { useEffect, useState } from "react";
import { getTextColor, log } from "../utils/utils";
import { getLanguageComponent } from "../utils/language";
import {incentiveAD, incentiveAD_jumpToRecharge} from "../utils/drawUtils";
import { LOCATION } from "../service/config";
import PopupAD from "./PopupAD";
import DialogOpenMember from "./DialogOpenMember";

interface IProps {
  initValue: number;
  setValue: Function;
  reset: boolean;
}

export enum relevancyEnum {
  低 = 1,
  中 = 2,
  高 = 3,
}

const Relevancy: React.FC<IProps> = (props) => {
  const { initValue, setValue, reset } = props;
  const lang = getLanguageComponent();
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });

  const [locks, setLocks] = useState({
    2: false,
    3: false,
  });
  useEffect(() => {
    if (reset) {
      console.log("页面已跳转,Relevancy,状态重置");
      setLocks({ 2: false, 3: false });
    }
  }, [reset]);
  const changeHandler = async (index: relevancyEnum.中 | relevancyEnum.高) => {
    if (locks[index]) {
      setValue(index);
    } else {
      await incentiveAD_jumpToRecharge(
        () => callback(index),
        setShowAdBtn,
        setIsShowPopupAD,
        setAdEndCallback,
        setIsShowMember,
        LOCATION.ADVANCED_DRAW_SETTING
      );
    }
  };

  const callback = (n: relevancyEnum) => {
    setValue(n);
    setLocks((prevLocks) => ({ ...prevLocks, [n]: true }));
  };
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/0.png')]"></div>
        {lang.描述词相关度}
      </div>
      <div className="item-body-rounded">
        <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
          <div
            onClick={() => {
              log("relevancy_click", { value: relevancyEnum.低.toString() });
              setValue(relevancyEnum.低);
            }}
            className={
              "option " +
              (initValue === 1 ? "option-active " + getTextColor() : "")
            }
          >
            {lang.低}
          </div>
          <div className="option-space">
            {initValue === relevancyEnum.高 ? "|" : ""}
          </div>
          <div
            onClick={() => {
              log("relevancy_click", { value: relevancyEnum.中.toString() });
              setValue(relevancyEnum.中);
            }}
            className={
              "option " +
              (initValue === relevancyEnum.中
                ? "option-active " + getTextColor()
                : "")
            }
          >
            {lang.中}
          </div>
          <div className="option-space">
            {initValue === relevancyEnum.低 ? "|" : ""}
          </div>
          <div
            onClick={() => {
              log("relevancy_click", { value: relevancyEnum.高.toString() });
              changeHandler(relevancyEnum.高);
            }}
            className={
              "option " +
              (initValue === relevancyEnum.高
                ? "option-active " + getTextColor()
                : "")
            }
          >
            {lang.高}
            <div className="z"></div>
          </div>
        </div>
      </div>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to unlock limit"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </>
  );
};

export default Relevancy;
