import React from "react";
import { isShowInput } from "../../utils/utils";
import { collect11, collect22, like11, like22 } from "../../service/config";

interface IProps {
  isShow: boolean;
  downloadHandler: Function;
  likeHandler: Function;
  previewImage: string;
  liked: boolean;
  setIsShowBigImage: Function;
}

let clickTimer: any = null;
const Footer: React.FC<IProps> = (props) => {
  const {
    isShow,
    downloadHandler,
    likeHandler,
    previewImage,
    liked,
    setIsShowBigImage,
  } = props;
  const previewHandleClick2 = (event: any) => {
    if (!clickTimer) {
      clickTimer = setTimeout(function () {
        setIsShowBigImage(false);
        clickTimer = null;
      }, 300); // 设置延迟时间
    } else {
      clearTimeout(clickTimer);
      clickTimer = null;
      likeHandler(true, event);
    }
  };
  return isShow ? (
    <div
      onClick={previewHandleClick2}
      className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-black z-50"
    >
      <img className="h-full w-full object-contain" src={previewImage} />
      <div className="absolute bottom-4.5 right-88 left-88 flex justify-between">
        <div
          onClick={(event) => {
            event.stopPropagation()
            downloadHandler();
          }}
          className="bg-[url('../assets/images/download2.png')] w-5 h-5 bg-contain bg-no-repeat bg-100  "
        ></div>
        <div
          onClick={(event) => likeHandler(false, event)}
          className={`${
            liked
              ? isShowInput("collect")
                ? collect22
                : like22
              : isShowInput("collect")
              ? collect11
              : like11
          } w-5 h-5 bg-contain bg-no-repeat bg-100`}
        ></div>
      </div>
    </div>
  ) : null;
};

export default Footer;
