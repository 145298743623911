import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: number;
}

enum relevancy {
  低 = 1,
  中 = 2,
  高 = 3,
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();

  return value !== 0 ? (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.相}</span>
        <span>{lang.似}</span>
        <span>{lang.度}：</span>
      </div>
      <div className="p-0.67 bg-bg rounded-0.42">
        {value === relevancy.低
          ? lang.低
          : value === relevancy.中
          ? lang.中
          : lang.高}
      </div>
    </div>
  ) : null;
};

export default Header;
