import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: number;
}

enum ratio {
  正方形 = 1,
  横图 = 2,
  竖图 = 3,
  Auto = 100,
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();
  const getRatio = (index: number) => {
    if (ratio.正方形 === index) {
      return (
        <>
          <div className="ratio-icon w-3.33 h-3.33"></div>
          {lang.正方形}
        </>
      );
    } else if (ratio.竖图 === index) {
      return (
        <>
          <div className="ratio-icon w-2 h-3.33"></div>
          {lang.竖图}
        </>
      );
    } else if (ratio.横图 === index) {
      return (
        <>
          <div className="ratio-icon w-3.33 h-2"></div>
          {lang.横图}
        </>
      );
    } else if (ratio.Auto === index) {
      return (
        <>
          <div
            className={
              "w-2 h-2 bg-contain mr-0.33 bg-[url('../assets/images/auto.png')]"
            }
          ></div>
          Auto
        </>
      );
    }
  };
  return value !== 0 ? (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.选}</span>
        <span>{lang.择}</span>
        <span>{lang.比}</span>
        <span>{lang.例}：</span>
      </div>
      <div className="flex items-center">{getRatio(value)}</div>
    </div>
  ) : null;
};

export default Header;
