import React, { useState } from "react";
import { InfiniteScroll, Tabs } from "antd-mobile";
import { formatTime, isAiBeauty } from "../utils/utils";
import { getCoinStream, getOrderList } from "../service/api";

let orderPageInfo = "";
let creditPageInfo = "";
const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [orderListHasMore, setOrderListHasMore] = useState(true);
  const [creditListHasMore, setCreditListHasMore] = useState(true);
  const [orderList, setOrderList] = useState<
    {
      mdseName: string;
      id: number;
      payTime: number;
      price: number;
    }[]
  >([]);
  const [creditList, setCreditList] = useState<
    {
      aiPoint: number;
      id: number;
      transactionTime: number;
      type: number;
      name: string;
    }[]
  >([]);

  async function orderListMore() {
    const response: any = await getOrderList({ pageInfo: orderPageInfo });

    setOrderList([...orderList, ...response.data.list]);
    if (response.data.pageInfo) {
      orderPageInfo = response.data.pageInfo;
    }
    setOrderListHasMore(response.data.pageInfo);
  }

  async function creditListMore() {
    const response: any = await getCoinStream({ pageInfo: creditPageInfo });
    setCreditList([...creditList, ...response.data.list]);
    if (response.data.pageInfo) {
      creditPageInfo = response.data.pageInfo;
    }
    setCreditListHasMore(response.data.pageInfo);
  }

  return (
    <div className="bg-bg h-full">
      <Tabs
        className={
          "my-tabs fixed w-full bg-bg pb-1.33 " +
          (isAiBeauty ? "ai_beauty" : "with")
        }
        activeLineMode="fixed"
        defaultActiveKey={tabIndex.toString()}
        style={{
          "--fixed-active-line-width": "16px",
        }}
        onChange={(value) => {
          setTabIndex(Number(value));
        }}
      >
        <Tabs.Tab title={props.lang.Credits} key={0} />
        <Tabs.Tab title={props.lang.Payments} key={1} />
      </Tabs>
      {tabIndex === 0 ? (
        <>
          <div className="p-1.33 pt-3.83">
            {creditList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`bg-white px-1.33 py-1.17 rounded-1 ${
                    creditList.length - 1 === index ? "" : "mb-1.33"
                  }`}
                >
                  <div className="flex items-center justify-between font-bold text-1.33 mb-0.67">
                    <div>{item.name}</div>
                    <div>
                      {item.aiPoint > 0 ? "+" + item.aiPoint : item.aiPoint}
                    </div>
                  </div>
                  <div className="text-card text-1">
                    {formatTime(item.transactionTime)}
                  </div>
                </div>
              );
            })}
          </div>
          {creditList.length === 0 && (
            <div className="flex justify-center items-center h-full">
              No data available
            </div>
          )}
          <InfiniteScroll
            loadMore={creditListMore}
            hasMore={creditListHasMore}
            children={null}
          />
        </>
      ) : (
        <>
          <div className="p-1.33 pt-3.83">
            {orderList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`bg-white px-1.33 py-1.17 rounded-1 ${
                    orderList.length - 1 === index ? "" : "mb-1.33"
                  }`}
                >
                  <div className="flex items-center justify-between font-bold text-1.33 mb-0.67">
                    <div>{item.mdseName}</div>
                    <div>${item.price / 100}</div>
                  </div>
                  <div className="text-card text-1">
                    {formatTime(item.payTime)}
                  </div>
                </div>
              );
            })}
          </div>
          {orderList.length === 0 && (
            <div className="flex justify-center items-center h-full">
              {props.lang["No data available"]}
            </div>
          )}
          <InfiniteScroll
            loadMore={orderListMore}
            hasMore={orderListHasMore}
            children={null}
          />
        </>
      )}
    </div>
  );
};

export default App;
