import React, { useEffect, useState } from "react";
import { Toast } from "antd-mobile";
import { getSuggestedPrompts } from "../../service/api";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  initValue: string;
  setValue: Function;
  removeObject?: boolean;
  setRemoveObject?: Function;
}

const maxLength = 500;
const prompts: Array<string> = [];
const Prompt: React.FC<IProps> = (props) => {
  const { initValue, setValue, removeObject } = props;
  const lang = getLanguageComponent();
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  window.monitorSoftKeyboard = function (height: number) {
    setKeyboardHeight(height);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(
      event.currentTarget.value.length > maxLength
        ? event.currentTarget.value.substring(0, maxLength)
        : event.currentTarget.value
    );
  };

  const clearPrompts = () => {
    if (initValue === "") {
      Toast.show(lang.无可清空内容);
    } else {
      setValue("");
    }
  };

  useEffect(() => {
    getSuggestedPrompts({ type: 5 }).then((res: any) => {
      if (res.code === 0) {
        prompts.push(...res.data.list);
      }
    });
  }, []);
  return (
    <>
      <div className="flex leading-none text-1.33  mb-1.33  items-center justify-between">
        <div className="text-34 text-1.33 flex items-center font-bold">
          <div className="icon bg-[url('../assets/images/icon/0.png')]"></div>
          {lang.输入描述语}
          {!removeObject && <span className="text-red text-251">*</span>}
        </div>
      </div>
      <div
        className={`${
          keyboardHeight ? "fixed left-0 w-full bg-white p-1.33 z-100" : ""
        }`}
        style={{
          bottom: `${keyboardHeight}px`,
        }}
      >
        <div className={`item-body-rounded ${keyboardHeight ? "mb-0" : ""}`}>
          <textarea
            className="w-full resize-none h-5 outline-none placeholder-201"
            placeholder={"What you want to see in your image"}
            onInput={handleChange}
            value={initValue}
            maxLength={maxLength}
          ></textarea>
          <div className="leading-none text-201 flex justify-between items-center">
            <div className="flex ">
              <div
                onClick={() => clearPrompts()}
                className="text-1 flex items-center border-gray border-0.25 rounded-1.67 bg-bg px-0.42 py-0.25 text-201 font-bold"
              >
                <div className="bg-[url('../assets/images/del2.png')] bg-contain h-1.33 w-1.33 mr-0.33"></div>
                {lang.清空文本}
              </div>
            </div>
            {initValue.length}/500
          </div>
        </div>
      </div>
      {keyboardHeight !== 0 && (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 bottom-0 right-0"></div>
      )}
    </>
  );
};

export default Prompt;
