import zh_CN from "../language/zh";
import en from "../language/en";
import zh_HK from "../language/hk";
import zh_TW from "../language/tw";
import id from "../language/id";
import vi from "../language/vi";
import ko from "../language/ko";
import ja from "../language/ja";
import th from "../language/th";
import fr from "../language/fr";
import it from "../language/it";
import de from "../language/de";

export const getLanguageComponent = () => {
  const match = window.navigator.userAgent.match(/language\/([^/]+)/);
  // const match ='xx language/vi_TW ss'.match(/language\/(\w+)/);
  const lang_region = match ? match[1] : "en_US";
  const lang = lang_region.split("-")[0].split("_")[0];

  const zhMaps: any = {
    "zh-Hans": zh_CN,
    "zh-Hant": zh_HK,
    zh_CN,
    zh_HK,
    zh_TW,
  };

  const languageMappings: any = {
    id: id,
    vi: vi,
    ko: ko,
    ja: ja,
    th: th,
    en: en,
    fr: fr,
    it: it,
    de: de,
  };

  if (lang_region.includes("zh-Hans") || lang_region.includes("zh-Hant")) {
    const f = lang_region.split("-");
    f.pop();
    return zhMaps[f.join("-")];
  } else {
    return zhMaps[lang_region] || languageMappings[lang] || en;
  }
};
