export default {
  lang: "de",
  AI绘画: "AiLady",
  输入描述语: "Eingabeaufforderung",
  随机一个: "Inspiration",
  清空文本: "Prompt löschen",
  选择风格: "Stil wählen",
  选择比例: "Seitenverhältnis",
  上传参考图: "Bild hochladen",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "Das ausgewählte Bild wird als Referenz für das Endergebnis verwendet",
  质量: "Qualität",
  标准: "Standard",
  更多细节: "Mehr Details",
  描述词相关度: "CFG-Skala",
  低: "Niedrig",
  中: "Mittel",
  高: "Hoch",
  友情提醒:
    "Freundliche Erinnerung: Bitte halten Sie sich an die Bilderschaffungsstandards und verbieten Sie das Einreichen von illegalen Bildinhalten. Illegale Inhalte können vom System abgefangen werden, und Ihr Konto könnte ebenfalls gesperrt werden.",
  生成画作: "Erzeugen",
  "AI绘画发起中...": "Analyse beginnen...",
  "AI绘画解析中...": "Weitere Details hinzufügen...",
  "AI绘画生成中...": "Erzeugen...",
  无可清空内容: "Nichts zu löschen...",
  请输入描述语: "Bitte geben Sie zuerst eine Eingabeaufforderung ein",
  提示: "Hinweis",
  确认删除该画作: "Sind Sie sicher, dass Sie es jetzt löschen möchten?",
  取消: "Abbrechen",
  确定: "OK",
  图片举报成功感谢你的反馈: "Erfolgreich gemeldet",
  描: "",
  述: "",
  词: "Prompt",
  选: "",
  择: "",
  风: "",
  格: "Stil",
  比: "",
  例: "Verhältnis",
  质: "",
  量: "Qualität",
  参: "",
  考: "",
  图: "Bild",
  相: "",
  似: "",
  度: "CFG",
  重绘: "Neu erstellen",
  删除: "Löschen",
  下载: "Herunterladen",
  喜欢: "Gefällt mir",
  请检查网络: "Bitte überprüfen Sie das Netzwerk",
  您还暂未开通会员: "Pro beitreten",
  立即开通: "Fortfahren",
  开通会员描述:
    "Genießen Sie unbegrenzte KI-Kreativität & ein fortgeschrittenes Malerlebnis",
  历史记录: "Verlauf",
  收藏: "Markieren",
  智能替换: "Objekt entfernen",
  请您先在图片上标记想要替换的元素:
    "Bitte markieren Sie zuerst die Elemente, die Sie auf dem Bild ersetzen möchten～",
  选择替换区域: "Ersatzbereich auswählen",
  快速选择: "Alles ausschneiden",
  笔刷: "Pinsel",
  橡皮擦: "Radierer",
  图片分区智能识别中: "Verarbeitung beginnen",
  开始下载: "Download beginnen...",
  "Reference image": "Posebild",
  Optional: "Optional",
  "Image similarity": "Bildähnlichkeit",
  "Higher parameter,closer to original image.":
    "Höherer Parameter, näher am Originalbild.",
  "Looks like": "Gesichtsabgleich",
  "Pose Matching to a Reference Image": "Pose-Abgleich mit einem Referenzbild",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Stellt einfach ein Referenzfoto zur Verfügung, und unsere Technologie repliziert mühelos seine Pose in einem neuen Bild.",
  "OK,I got it!": "OK, verstanden!",
  "How to make a quality creation": "Wie man eine qualitativ hochwertige Kreation macht",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Bitte laden Sie ein Foto mit klarem, frontalem Gesicht hoch. Ein Foto schlechter Qualität führt zu einer schlechten Kreation.",
  Credit: "Guthaben",
  Credits: "Guthaben",
  Buy: "Kaufen",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Ihr Guthaben reicht nicht aus, um diese Funktion zu nutzen. Bitte besuchen Sie das Kaufzentrum, um weitere Guthaben zu erwerben",
  "Upgrade to Pro ?": "Auf Pro upgraden?",
  "Or continue without Pro library": "Oder ohne Pro-Bibliothek fortfahren",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "Die Kreation kann NSFW-Inhalte enthalten.",
  "Don't ask again for 30 days": "Für 30 Tage nicht wieder fragen",
  "I understand and wish to proceed": "Ich verstehe und möchte fortfahren",
  "Watch ad to get free trial!": "Werbeanzeige ansehen, um kostenlose Probe zu bekommen!",
  "Upload photo": "Foto hochladen",
  Payments: "Zahlungen",
  "No data available": "Keine Daten verfügbar",
  "Subscribe to unlock limit": "Abonnieren, um Limit freizuschalten",
  "Watch ad to create": "Anzeige ansehen, um zu erstellen",
  ClaimSuccess: "Alles klar! ✅ Wir haben Ihr Guthaben für morgen gespeichert. Vergewissern Sie sich, dass Sie es beanspruchen, bevor es weg ist!",
  Magic:"Magie wird gebraut... 🧙‍"
};