import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: string;
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();
  return value !== "" ? (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.参}</span>
        <span>{lang.考}</span>
        <span>{lang.图}：</span>
      </div>
      <div>
        <img
          alt=""
          src={value}
          className="w-3.33 h-3.33 rounded-0.42"
        />
      </div>
    </div>
  ) : null;
};

export default Header;
