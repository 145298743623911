import React from "react";
import {
  commonInterface,
  getDownloadImage,
  getReDrawImage,
  isAndroid,
  isShowInput,
  log,
} from "../../utils/utils";
import { collect1, collect2, like1, like2 } from "../../service/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLanguageComponent } from "../../utils/language";
import { Dialog, Toast } from "antd-mobile";
import { remove } from "../../service/api";

interface IProps {
  detailId: string | null;
  liked: boolean;
  downloadHandler: Function;
  likeHandler: Function;
}

const Footer: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const from: any = params.get("from");
  const lang = getLanguageComponent();
  const { liked, detailId, downloadHandler, likeHandler } = props;
  const deleteHandler = async () => {
    log("delete_click");
    const result = await Dialog.confirm({
      bodyClassName: "delete-dialog",
      title: lang.提示,
      content: lang.确认删除该画作,
      cancelText: lang.取消,
      confirmText: lang.确定,
    });
    if (result) {
      remove({ id: detailId }).then((res: any) => {
        if (res.code === 0) {
          commonInterface("refreshImageHistory");
          commonInterface("closePage");
        } else {
          Toast.show(res.msg);
        }
      });
    }
  };

  return (
    <div
      className={
        "pt-1 " +
        (isAndroid ? "pb-12" : "pb-3.83") +
        " bg-white rounded-t-1.67 shadow-up z-40"
      }
    >
      <div className="flex justify-around p-0.42 rounded-0.67">
        <div
          onClick={() => {
            log("redraw_click");
            if (from) {
              commonInterface("closePage");
            } else {
              navigate("/draw?detail_id=" + detailId, {
                replace: true,
              });
            }
          }}
          className={"btn-item " + lang.lang}
        >
          <img
            alt=""
            className="w-1.67 h-1.67 mr-0.33"
            src={require("../../assets/images/" + getReDrawImage())}
          />
          {lang.重绘}
        </div>
        <div className="w-0.08 text-266">|</div>
        <div onClick={deleteHandler} className={"btn-item " + lang.lang}>
          <img
            alt=""
            className="w-1.67 h-1.67 mr-0.33"
            src={require("../../assets/images/del.png")}
          />
          {lang.删除}
        </div>
        <div className="w-0.08 text-266">|</div>
        <div
          onClick={(event) => {
            downloadHandler(event);
          }}
          className={"btn-item " + lang.lang}
        >
          <img
            alt=""
            className="w-1.67 h-1.67 mr-0.33"
            src={require("../../assets/images/" + getDownloadImage())}
          />
          {lang.下载}
        </div>
        <div className="w-0.08 text-266">|</div>
        <div
          onClick={(event) => likeHandler(false, event)}
          className={"btn-item " + lang.lang}
        >
          <img
            alt=""
            className="w-1.67 h-1.67 mr-0.33"
            src={
              liked
                ? isShowInput("collect")
                  ? collect2
                  : like2
                : isShowInput("collect")
                ? collect1
                : like1
            }
          />
          {isShowInput("collect") ? lang.收藏 : lang.喜欢}
        </div>
      </div>
    </div>
  );
};

export default Footer;
