export default {
  lang: "en",
  AI绘画: "วาดด้วย AI",
  输入描述语: "คำอธิบายการป้อนข้อมูล",
  随机一个: "สุ่มหนึ่ง",
  清空文本: "เคลียร์ข้อความ",
  选择风格: "เลือกสไตล์",
  选择比例: "เลือกอัตราส่วน",
  上传参考图: "อัปโหลดรูปภาพอ้างอิง",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作: "ให้ AI สร้างตามคำอธิบายจากรูปภาพอ้างอิง",
  质量: "คุณภาพ",
  标准: "มาตรฐาน",
  更多细节: "รายละเอียดเพิ่มเติม",
  描述词相关度: "ความเกี่ยวข้องของตัวบ่งชี้",
  低: "ต่ำ",
  中: "ปานกลาง",
  高: "สูง",
  友情提醒:
    'แจ้งเตือนเพื่อน: โปรดปฏิบัติตามมาตรฐานการสร้างภาพและห้ามส่งเนื้อหาที่ผิดกฎหมาย ระบบอาจพบเนื้อหาผิดกฎหมายและในกรณีที่รุนแรง บัญชีของคุณอาจถูกแบน โปรดอ้างถึง "',
  社区规范: 'มาตรฐานชุมชน" เพื่อรายละเอียดมาตรฐาน',
  生成画作: "สร้างงานศิลปะ",
  "AI绘画发起中...": "กำลังเริ่มวาดด้วย AI...",
  "AI绘画解析中...": "กำลังวิเคราะห์การวาดด้วย AI...",
  "AI绘画生成中...": "กำลังสร้างการวาดด้วย AI...",
  无可清空内容: "ไม่มีข้อความให้ล้าง~",
  请输入描述语: "โปรดป้อนคำอธิบาย",
  提示: "คำแนะนำ",
  确认删除该画作: "คุณแน่ใจหรือไม่ว่าต้องการลบภาพนี้?",
  取消: "ยกเลิก",
  确定: "ตกลง",
  图片举报成功感谢你的反馈: "รายงานรูปภาพสำเร็จ ขอบคุณสำหรับความคิดเห็นของคุณ",
  描: "",
  述: "",
  词: "คำอธิบาย",
  选: "",
  择: "",
  风: "",
  格: "สไตล์ที่เลือก",
  比: "",
  例: "อัตราส่วนที่เลือก",
  质: "",
  量: "คุณภาพ",
  参: "",
  考: "",
  图: "รูปภาพอ้างอิง",
  相: "",
  似: "",
  度: "ความคล้ายคลึง",
  重绘: "วาดใหม่",
  删除: "ลบ",
  下载: "ดาวน์โหลด",
  喜欢: "ชอบ",
  请检查网络: "โปรดตรวจสอบเครือข่าย",
  您还暂未开通会员: "คุณยังไม่ได้เปิดสมาชิก",
  立即开通: "เปิดทันที",
  开通会员描述:
    "ต่ำเพียง <span style='color:rgba(255, 181, 77, 1)'>0.01</span> หยวนต่อวันเพลิดเพลินกับการวาดภาพ AI ไม่ จำกัด จำนวนครั้ง",
  历史记录: "ประวัติศาสตร์",
  收藏:"คอลเลกชัน",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
