export default {
  lang: "en",
  AI绘画: "AI Menggambar",
  输入描述语: "Deskripsi Masukan",
  随机一个: "Acak satu",
  清空文本: "Bersihkan Teks",
  选择风格: "Pilih gaya",
  选择比例: "Pilih perbandingan",
  上传参考图: "Unggah gambar referensi",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "Biarkan AI menciptakan berdasarkan deskripsi berdasarkan gambar referensi",
  质量: "Kualitas",
  标准: "Standar",
  更多细节: "Lebih banyak detail",
  描述词相关度: "Relevansi deskriptor",
  低: "Rendah",
  中: "Sedang",
  高: "Tinggi",
  友情提醒:
    'Pengingat ramah: Harap patuhi standar pembuatan gambar dan larang pengiriman konten ilegal. Konten ilegal dapat diintersep oleh sistem, dan dalam kasus yang parah, akun Anda dapat dilarang. Silakan lihat "',
  社区规范: ' Standar Komunitas" untuk spesifikasi detail',
  生成画作: "Buat karya seni",
  "AI绘画发起中...": "Penggambaran AI dimulai",
  "AI绘画解析中...": "Analisis penggambaran AI berlangsung",
  "AI绘画生成中...": "Pembuatan penggambaran AI berlangsung",
  无可清空内容: "Tidak ada yang bisa dibersihkan~",
  请输入描述语: "Silakan masukkan deskripsi",
  提示: "Petunjuk",
  确认删除该画作: "Anda yakin ingin menghapus lukisan ini?",
  取消: "Batal",
  确定: "Oke",
  图片举报成功感谢你的反馈:
    "Berhasil melaporkan gambar. Terima kasih atas umpan balik Anda",
  描: "",
  述: "",
  词: "Kata Deskriptif",
  选: "",
  择: "",
  风: "",
  格: "Gaya Dipilih",
  比: "",
  例: "Proporsi Dipilih",
  质: "",
  量: "Kualitas",
  参: "",
  考: "",
  图: "Gambar Referensi",
  相: "",
  似: "",
  度: "Kemiripan",
  重绘: "Gambar Ulang",
  删除: "Hapus",
  下载: "Unduh",
  喜欢: "Suka",
  请检查网络:'Silakan periksa jaringan',
  您还暂未开通会员: "Anda belum membuka anggota",
  立即开通: "aktifkan sekarang",
  开通会员描述:
      "Sekurang <span style='color:rgba(255, 181, 77, 1)'>0,01 yuan</span> per hari, menikmati lukisan AI tak terbatas, jumlah tidak terbatas kali",
  历史记录: "sejarah",
  收藏:"koleksi",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
