export default {
  lang: "en",
  AI绘画: "AI 그리기",
  입력描述语: "입력 설명",
  随机一个: "랜덤 선택",
  清空文本: "텍스트 지우기",
  选择风格: "스타일 선택",
  选择比例: "비율 선택",
  上传参考图: "참고 이미지 업로드",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "AI가 참고 이미지를 기반으로 설명에 따라 작품을 생성합니다",
  质量: "품질",
  标准: "표준",
  更多细节: "더 많은 세부 정보",
  描述词相关度: "서술자 관련성",
  低: "낮음",
  中: "보통",
  高: "높음",
  友情提醒:
    '친절한 안내: 이미지 작성 기준을 준수하고 불법 콘텐츠 제출을 금지하세요. 불법 콘텐츠는 시스템에서 가로챌 수 있으며, 심각한 경우 계정이 차단될 수 있습니다. 자세한 사항은 "',
  社区规范: '커뮤니티 기준"을 참조하세요',
  生成画作: "작품 생성",
  "AI绘画发起中...": "AI 그림 시작 중...",
  "AI绘画解析中...": "AI 그림 분석 중...",
  "AI绘画生成中...": "AI 그림 생성 중...",
  无可清空内容: "지울 내용 없음~",
  请输入描述语: "설명을 입력하세요",
  提示: "알림",
  确认删除该画作: "이 그림을 삭제하시겠습니까?",
  取消: "취소",
  确定: "확인",
  图片举报成功感谢你的反馈:
    "이미지 신고가 성공적으로 접수되었습니다. 피드백 감사합니다",
  描: "",
  述: "",
  词: "서술적인 단어",
  选: "",
  择: "",
  风: "",
  格: "선택한 스타일",
  比: "",
  例: "선택한 비율",
  质: "",
  量: "품질",
  参: "",
  考: "",
  图: "참고 이미지",
  相: "",
  似: "",
  度: "유사도",
  重绘: "재그리기",
  删除: "삭제",
  下载: "다운로드",
  喜欢: "좋아요",
  请检查网络: "네트워크를 확인하십시오",
  您还暂未开通会员: "당신은 아직 회원을 개설하지 않았습니다",
  立即开通: "즉시 개통",
  开通会员描述:
    "<span style='color:rgba(255, 181, 77, 1)'>0.01</span>위안으로 낮음, 횟수 제한 없이 무제한 AI 회화를 즐길 수 있다",
  历史记录: "역사 기록",
  收藏:"모음집",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
