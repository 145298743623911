import React, { useState } from "react";
import {
  CheckCircleFill,
  CheckCircleOutline,
  CloseCircleOutline,
  ExclamationCircleFill,
} from "antd-mobile-icons";
import { Checkbox } from "antd-mobile";
import { commonInterface } from "../utils/utils";
import { nsfwIgnore } from "../service/api";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
}

const expirationDay = 30;
const Footer: React.FC<IProps> = (props) => {
  const { isShow, hideDialog } = props;
  const [isCheck, setIsCheck] = useState(false);

  const dontShowHandler = () => {
    if (isCheck) {
      nsfwIgnore({ days: expirationDay }).then((res) => {
        commonInterface("refreshImageHistory");
      });
    }
    hideDialog(false);
  };

  return isShow ? (
    <div className="fixed w-full left-0 top-0 h-full bg-black flex items-center justify-center z-50">
      <div className="bg-40 text-181 w-90 rounded-1 p-1.33 flex flex-col items-center relative">
        <CloseCircleOutline
          fontSize="20"
          className="absolute right-0.5 top-0.5"
          onClick={() => {
            commonInterface("closePage");
          }}
        />
        <ExclamationCircleFill
          fontSize="40"
          color="rgb(250,65,66)"
          className="mb-1"
        />
        <div>The creation may contain NSFW content.</div>
        <div className="mb-2.5 text-1 flex items-center">
          <Checkbox
            onChange={(checked) => setIsCheck(checked)}
            icon={(checked) =>
              checked ? <CheckCircleFill /> : <CheckCircleOutline />
            }
            defaultChecked={isCheck}
          >
            Don't ask again for {expirationDay} days
          </Checkbox>
        </div>
        <div
          onClick={dontShowHandler}
          className="rounded-1 p-1 bg-66 self-stretch text-center"
        >
          <div className="nsfw-btn-text">I understand and wish to proceed</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Footer;
