import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";
import { getSuggestedPrompts } from "../service/api";

import { getRefImage, getTextColor, log } from "../utils/utils";

interface IProps {
  initValue: string;
  setValue: Function;
  prompts: string[];
  examplePrompt:string
}

const maxLength = 500;

const Prompt: React.FC<IProps> = (props) => {
  const { initValue, setValue, prompts,examplePrompt } = props;
  const lang = getLanguageComponent();
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  window.monitorSoftKeyboard = function (height: number) {
    setKeyboardHeight(height);
  };
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(
      event.currentTarget.value.length > maxLength
        ? event.currentTarget.value.substring(0, maxLength)
        : event.currentTarget.value
    );
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // 阻止默认的换行行为
      event.currentTarget.blur(); // 收起软键盘
    }
  };
  const clearPrompts = () => {
    if (initValue === "") {
      Toast.show(lang.无可清空内容);
    } else {
      setValue("");
    }
  };
  const randomPrompts = useCallback(
    (e: any) => {
      e.stopPropagation();
      log("random_prompt_click");
      const n = (Math.random() * prompts.length) >> 0;
      setValue(prompts[n] ? prompts[n] : "");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [prompts]
  );

  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/0.png')]"></div>
        {lang.输入描述语}
        <span className="text-red text-251">*</span>
      </div>
      <div
        className={`${
          keyboardHeight
            ? "fixed top-0 left-0 w-full bg-white p-1.33 z-100 pt-55"
            : ""
        }`}
      >
        <div className={`item-body-rounded ${keyboardHeight ? "mb-0" : ""}`}>
          <textarea
            ref={inputRef}
            className={`w-full resize-none h-5 outline-none placeholder-201`}
            placeholder={examplePrompt}
            onInput={handleChange}
            value={initValue}
            maxLength={500}
            onKeyDown={handleKeyDown}
          ></textarea>
          <div className="leading-none text-201 flex justify-between items-center">
            <div className="flex ">
              <div
                onClick={(e) => randomPrompts(e)}
                className={
                  "text-1 flex items-center border-gray border-0.25 rounded-1.67 px-0.42 py-0.25 font-bold mr-1 " +
                  getTextColor()
                }
              >
                <div
                  className={
                    getRefImage() + " bg-contain h-1.33 w-1.33 mr-0.33"
                  }
                ></div>
                {lang.随机一个}
              </div>
              <div
                onClick={() => clearPrompts()}
                className="text-1 flex items-center border-gray border-0.25 rounded-1.67 bg-bg px-0.42 py-0.25 text-201 font-bold"
              >
                <div className="bg-[url('../assets/images/del2.png')] bg-contain h-1.33 w-1.33 mr-0.33"></div>
                {lang.清空文本}
              </div>
            </div>
            {initValue.length}/500
          </div>
        </div>
      </div>
      {keyboardHeight !== 0 && (
        <div className="bg-black bg-opacity-50 fixed top-0 left-0 bottom-0 right-0 z-10"></div>
      )}
    </>
  );
};

export default Prompt;
