export default {
  lang: "en",
  AI绘画: "AI 繪畫",
  输入描述语: "輸入描述",
  随机一个: "隨機一個",
  清空文本: "清除文字",
  选择风格: "選擇風格",
  选择比例: "選擇比例",
  上传参考图: "上載參考圖片",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作: "讓 AI 根據描述在參考圖片的基礎上進行創作",
  质量: "品質",
  标准: "標準",
  更多细节: "更多細節",
  描述词相关度: "描述詞相關度",
  低: "低",
  中: "中",
  高: "高",
  友情提醒:
    "友好提醒：請遵守圖像創作標準，禁止提交違法內容。違法內容可能被系統攔截，嚴重情況下，您的帳戶可能會被封禁。請參考「",
  社区规范: " 社區標準」以獲得詳細規範",
  生成画作: "生成藝術品",
  "AI绘画发起中...": "AI 繪畫啟動中...",
  "AI绘画解析中...": "AI 繪畫分析中...",
  "AI绘画生成中...": "AI 繪畫生成中...",
  无可清空内容: "無可清除的內容~",
  请输入描述语: "請輸入描述",
  提示: "提示",
  确认删除该画作: "您確定要刪除這幅畫嗎？",
  取消: "取消",
  确定: "確定",
  图片举报成功感谢你的反馈: "圖片舉報成功。感謝您的反饋",
  描: "描",
  述: "述",
  词: "詞",
  选: "選",
  择: "定",
  风: "風",
  格: "格",
  比: "比",
  例: "例",
  质: "品",
  量: "質",
  参: "參",
  考: "考",
  图: "圖",
  相: "相",
  似: "似",
  度: "度",
  重绘: "重新繪製",
  删除: "刪除",
  下载: "下載",
  喜欢: "喜歡",
  请检查网络:'請檢查網絡',
  您还暂未开通会员: "您還暫未開通會員",
  立即开通: "立即開通",
  开通会员描述:
      "低至<span style='color:rgba(255, 181, 77, 1)'>0.01元</span>每天，暢享無限AI繪畫，不限制次數",
  历史记录: "歷史記錄",
  收藏:"收藏",
  智能替换:"Remove Object",
  请您先在图片上标记想要替换的元素:"Please mark the elements you want to replace on the image first～",
  选择替换区域:"Select replacement area",
  快速选择:"Cut everything",
  笔刷:"Brush",
  橡皮擦:"Erase",
  图片分区智能识别中:"Start processing the image",
  开始下载:"Start downloading...",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
