import React, { useEffect, useState, useRef } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Toast, Tabs, Swiper } from "antd-mobile";
import {
  createGraphApi,
  detail,
  galleryDetail,
  getStyleListApi,
  styleTabs,
  avatarSamples,
} from "../service/api";
import { Upload } from "../service/upload";
import {
  commonInterface,
  run,
  isCanCreate,
  getTextColor,
  getBgColor,
  getBorderColor,
  isAiBeauty,
  isAndroid,
  getReDrawImage,
  getSelectPicImage,
} from "../utils/utils";
import {
  isInApp,
  waitFor,
  nativeUploadHandler,
  getStyleBoxWidth,
} from "../utils/drawUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/draw.css";

const loadingText: Array<string> = [
  "AI绘制发起中...",
  "AI绘制解析中...",
  "AI绘制生成中...",
];
let timer: any;

const styleCacheData: any = [];
let isScrolling: boolean = false;
let scrollStoppedTimeout: any;
const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const galleryDetailId = params.get("gallery_detail_id");
  const [styleList, setStyleList] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [isShowMember, setIsShowMember] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const divRef = useRef(null);
  const [data, setData] = useState({
    style: { id: 0, name: "", icon: "" },
    inputImage: "",
    content:
      '{"type":2,"images":[{"url":"","originSize":0,"width":0,"height":0,"duration":0}]}',
  });

  const getDetailInfo = () => {
    if (galleryDetailId) {
      return galleryDetail({ id: galleryDetailId }).then((res: any) => {
        return res.data;
      });
    } else if (detailId) {
      return detail({ id: detailId }).then((res: any) => {
        return res.data;
      });
    } else {
      return null;
    }
  };

  const getStyleData = (tab: number, pageInfo: string): any => {
    return getStyleListApi({ tab, pageInfo, type: 2 }).then((res: any) => {
      if (res.data.pageInfo) {
        return getStyleData(tab, res.data.pageInfo).then((resNextData: any) => {
          return [...res.data.list, ...resNextData];
        });
      } else {
        return res.data.list;
      }
    });
  };

  const getTabListData = () => {
    return styleTabs({ type: 2 }).then((res: any) => {
      if (res.code === 0) {
        setTabList(res.data.list);
        return getStyleData(0, "");
      }
    });
  };
  const changeStyleTabHandler = (key: any) => {
    const styleData = styleCacheData[key];
    if (styleData) {
      setStyleList(styleData);
      setData({ ...data, style: styleData[0] });
    } else {
      getStyleData(key, "").then((res: any) => {
        setStyleList(res);
        setData({ ...data, style: res[0] });
        styleCacheData[key] = res;
      });
    }
  };
  useEffect(() => {
    avatarSamples({}).then((res: any) => {
      if (res.code === 0) {
        setAvatars(res.data.list);
      }
    });
    Promise.all([getTabListData(), getDetailInfo() as any]).then((res) => {
      setStyleList(res[0]);
      styleCacheData[0] = res[0];
      if (res[1]) {
        //如果风格里面曾经的删除了。就默认选第一个
        if (!res[1].style) {
          res[1].style = res[0][0];
        } else {
          const item = res[0].find((item: any) => item.id === res[1].style.id);
          if (!item) {
            res[1].style = res[0][0];
          }
        }
        setData({ ...data, ...res[1] }); //如有detailID,就用如有detailID的结果
      } else {
        setData({ ...data, style: res[0][0] }); //默认选第一个
      }
    });
  }, []);

  const checkForm = () => {
    let result = true;
    const errMsgArr = [];
    if (data.inputImage === "") {
      errMsgArr.push("请选择照片");
      result = false;
    }
    if (errMsgArr.length > 0) {
      Toast.show(errMsgArr.join("，"));
    }
    return result;
  };

  const showLoadingText = (index: number) => {
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: loadingText[index],
    });

    timer = setTimeout(() => {
      index = index < loadingText.length - 1 ? index + 1 : index;
      showLoadingText(index);
      if (index === loadingText.length - 1) {
        clearTimeout(timer);
      }
    }, 5000);
  };

  const submitHandler = async () => {
    if (checkForm()) {
      if (btnDisable) return;
      setBtnDisable(true);
      try {
        const isCan = await isCanCreate();
        if (isCan) {
          const inputImage = data.inputImage;
          const styleId = data.style.id;
          showLoadingText(0);
          const createResult: any = await createGraphApi({
            inputImage,
            styleId,
            type: 2,
          });

          if (createResult.code === 0) {
            commonInterface("setDrawingId", {
              drawingId: createResult.data.id,
            });
            const random = (Math.random() * 8 + 3) >> 0; // 3 ~ 10秒，之后在请求
            console.info(random + "秒后，发起第一次请求");
            await waitFor(random * 1000);
            const result = await run(createResult.data.id);
            console.info(result);
            Toast.clear();
            timer && clearTimeout(timer);
            setBtnDisable(false);
            commonInterface("setDrawingId", { drawingId: "" });
            if (result["code"] === 0) {
              commonInterface("refreshImageHistory");
              const scheme =
                "with://com.with.fm/app/h5?url=" +
                encodeURIComponent(
                  window.location.origin +
                    "/photo_result?from=draw&isFullScreen=true&detail_id=" +
                    result["data"]["id"]
                );
              commonInterface("openScheme", { scheme });
            } else {
              Toast.show(result["msg"]);
            }
          } else {
            Toast.clear();
            timer && clearTimeout(timer);
            setBtnDisable(false);
            Toast.show(createResult["msg"]);
          }
        } else {
          setIsShowMember(true);
          setBtnDisable(false);
        }
      } catch (e: any) {
        setBtnDisable(false);
      }
    }
  };

  const uploadImage = async (event: any) => {
    const file: any = event.target.files[0];
    const res: any = await Upload(file);
    setData({ ...data, inputImage: res.url });
  };

  window.uploadImageResult = (url: string) => {
    setData({ ...data, inputImage: url });
  };
  const handleScrollStart = () => {
    isScrolling = true;
    console.info("start");
    commonInterface("onHorizontalScrollStart");
    clearTimeout(scrollStoppedTimeout);
  };

  const handleScrollStop = () => {
    isScrolling = false;
    scrollStoppedTimeout = setTimeout(() => {
      console.info("end");
      commonInterface("onHorizontalScrollEnd");
      // 执行相应的逻辑操作
    }, 200); // 设置延迟时间，单位为毫秒
  };
  return (
    <div className="bg-bg flex flex-col justify-between h-full pt-20">
      <div className="px-1.33 overflow-auto pb-2.5">
        <div className="mb-40 mx-auto w-235 relative ">
          {data.inputImage === "" ? (
            <div className="h-235 mb-33">
              <Swiper
                className="photo-swiper w-235  "
                loop
                autoplay
                style={{
                  "--track-padding": " 0 0 18px",
                }}
              >
                {avatars.map((item, index) => {
                  return (
                    <Swiper.Item key={index} className="h-235">
                      <img className="w-235 h-235 rounded-2.5" src={item}></img>
                    </Swiper.Item>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            <div
              className="w-235 h-235 bg-no-repeat bg-center rounded-2.5 bg-contain mb-20 "
              style={{ backgroundImage: "url(" + data.inputImage + ")" }}
            ></div>
          )}
          <div
            onClick={nativeUploadHandler}
            className={
              "border-0.08 rounded-2 flex justify-center items-center text-1.33 font-bold py-1.08  bottom-0 w-full " +
              getTextColor() +
              " " +
              getBorderColor()
            }
          >
            <img
              src={require("../assets/images/" + getSelectPicImage())}
              width="22"
              className="mr-0.67"
            />
            选择照片
            {isInApp() ? null : (
              <input
                type="file"
                className="opacity-0 h-6.67 w-6.67 absolute"
                id="input"
                onChange={uploadImage}
              ></input>
            )}
          </div>
        </div>
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/1.png')]"></div>
          选择风格<span className="text-red text-251">*</span>
        </div>
        <div
          className={
            "rounded-1.67 pl-1.33 pr-1.33 mb-2.5 border-0.08 border-242 " +
            (tabList.length > 0 ? "pt-1.33" : "")
          }
        >
          <div>
            <Tabs
              className={
                "my-tabs my-photo-tabs " + (isAiBeauty ? "ai_beauty" : "with")
              }
              activeLineMode="fixed"
              defaultActiveKey="0"
              style={{
                "--fixed-active-line-width": "20px", // 自定义宽度
              }}
              onChange={changeStyleTabHandler}
            >
              {tabList.map((item: any) => {
                return <Tabs.Tab title={item.name} key={item.id} />;
              })}
            </Tabs>
          </div>
          <div
            ref={divRef}
            onScroll={() => {
              if (!isScrolling) {
                handleScrollStart();
              }

              clearTimeout(scrollStoppedTimeout);
              scrollStoppedTimeout = setTimeout(handleScrollStop, 200); // 设置延迟时间，单位为毫秒
            }}
            className="item-body-rounded-style"
            style={{ width: getStyleBoxWidth(styleList.length) }}
          >
            {styleList.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    "w-6.67" +
                    (index === styleList.length - 1 ||
                    index === styleList.length - 2
                      ? ""
                      : " mr-1")
                  }
                  onClick={() =>
                    setData({ ...data, style: { ...data.style, id: item.id } })
                  }
                >
                  <div className="w-6.67 h-6.67 relative rounded-1.33 overflow-hidden">
                    <img alt="" src={item.icon} />
                    {data.style.id === item.id ? (
                      <div
                        className={
                          "absolute  rounded-1.33 top-0 w-full h-full border-2px " +
                          getBorderColor()
                        }
                      ></div>
                    ) : null}
                  </div>
                  <div
                    className={
                      "text-center pt-0.33 w-6.67 overflow-hidden white-space-nowrap overflow-ellipsis " +
                      (data.style.id === item.id ? getTextColor() : "")
                    }
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-201 text-1">
          {props.lang.友情提示}
          <div
            className={"text-center pt-30 text-16 " + getTextColor()}
            onClick={() => {
              const scheme = "with://com.with.fm/app/ai/avatar/history";
              commonInterface("openScheme", { scheme });
            }}
          >
            历史头像
          </div>
        </div>
      </div>
      <div
        className={
          "pt-1 px-1.33 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up " +
          (btnDisable ? "opacity-50" : "")
        }
        onClick={submitHandler}
      >
        <div
          className={
            "rounded-2.33 p-1.17 text-1.67 text-center text-white " +
            getBgColor()
          }
        >
          生成头像
        </div>
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </div>
  );
};

export default App;
