import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: String;
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();
  return (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.描}</span>
        <span>{lang.述}</span>
        <span>{lang.词}：</span>
      </div>
      <div className="flex-1 break-all">{value}</div>
    </div>
  );
};

export default Header;
