import { getProfile, queryGraphApi } from "../service/api";
import { AppConfig, showConfig } from "../service/config";

export const isCanCreate = async () => {
  const res: any = await getProfile({});
  if (res.code == 0) {
    if (res["data"]["vip"] != null || res["data"]["aiPoint"] != 0) {
      return true;
    }
  }
  return false;
};
export const isVip = async () => {
  const res: any = await getProfile({});

  if (res.code == 0) {
    if (res["data"]["vip"] != null) {
      return true;
    }
  }
  return false;
};

const waitFor = (delay: any) =>
  new Promise((resolve) => setTimeout(resolve, delay));
// @ts-ignore

export const run = async (queryId: number) => {
  const response: any = await query(queryId);
  if (response.code === 330004) {
    return response;
  } else {
    if (!response.data.content && !response.msg) {
      await waitFor(response.data.nextInterval * 1000);
      return await run(queryId);
    }
    return response;
  }
};
export const query = async (queryId: number) => {
  try {
    return await queryGraphApi({ id: queryId });
  } catch (e) {
    console.info(e);
    return await queryGraphApi({ id: queryId });
  }
};

export const commonInterface = (funcName: string, params?: any): void => {
  const isAndroid =
    navigator.userAgent.indexOf("Android") > -1 ||
    navigator.userAgent.indexOf("Adr") > -1;
  try {
    if (isAndroid) {
      if (funcName === "shareImage") {
        window.android["shareImage"](params.url);
      } else if (funcName === "downloadImage") {
        window.android["downloadImage"](params.url);
      } else if (funcName === "uploadHDImage") {
        window.android["uploadHDImage"]();
      } else if (funcName === "closePage") {
        window.android["closePage"]();
      } else if (funcName === "refreshImageHistory") {
        window.android["refreshImageHistory"]();
      } else if (funcName === "uploadImage") {
        window.android["uploadImage"]();
      } else if (funcName === "uploadAvatar") {
        window.android["uploadAvatar"]();
      } else if (funcName === "openScheme") {
        window.android["openScheme"](params.scheme);
      } else if (funcName === "jumpToRecharge") {
        window.android["jumpToRecharge"]();
      } else if (funcName === "selectAiUser") {
        window.android["selectAiUser"](params.user);
      } else if (funcName === "setDrawingId") {
        window.android["setDrawingId"](params.drawingId);
      } else if (funcName === "onHorizontalScrollStart") {
        window.android["onHorizontalScrollStart"]();
      } else if (funcName === "onHorizontalScrollEnd") {
        window.android["onHorizontalScrollEnd"]();
      } else if (funcName === "logEvent") {
        window.android["logEvent"](
          params.eventName,
          params.valueToSum,
          params.parameters
        );
      }
    } else {
      if (funcName === "shareImage") {
        window.webkit.messageHandlers["shareImage"].postMessage(params.url);
      } else if (funcName === "downloadImage") {
        window.webkit.messageHandlers["downloadImage"].postMessage(params.url);
      } else if (funcName === "uploadHDImage") {
        window.webkit.messageHandlers["uploadHDImage"].postMessage("");
      } else if (funcName === "closePage") {
        window.webkit.messageHandlers["closePage"].postMessage("");
      } else if (funcName === "refreshImageHistory") {
        window.webkit.messageHandlers["refreshImageHistory"].postMessage("");
      } else if (funcName === "uploadImage") {
        window.webkit.messageHandlers["uploadImage"].postMessage("");
      } else if (funcName === "uploadAvatar") {
        window.webkit.messageHandlers["uploadAvatar"].postMessage("");
      } else if (funcName === "openScheme") {
        window.webkit.messageHandlers["openScheme"].postMessage(params.scheme);
      } else if (funcName === "jumpToRecharge") {
        window.webkit.messageHandlers["jumpToRecharge"].postMessage("");
      } else if (funcName === "selectAiUser") {
        window.webkit.messageHandlers["selectAiUser"].postMessage(params.user);
      } else if (funcName === "setDrawingId") {
        window.webkit.messageHandlers["setDrawingId"].postMessage(
          params.drawingId
        );
      } else if (funcName === "onHorizontalScrollStart") {
        window.webkit.messageHandlers["onHorizontalScrollStart"].postMessage(
          ""
        );
      } else if (funcName === "onHorizontalScrollEnd") {
        window.webkit.messageHandlers["onHorizontalScrollEnd"].postMessage("");
      } else if (funcName === "logEvent") {
        window.webkit.messageHandlers["logEvent"].postMessage(
          params.eventName,
          params.valueToSum,
          params.parameters
        );
      }
    }
  } catch (e: any) {
    // Toast.show({
    //   content: funcName + "," + e.toString(),
    // });
  }
};
export const isWechat = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("micromessenger");
};
export const isWeibo = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("weibo");
};
export const isAiBeauty =
  window.navigator.userAgent.match(/appName\/aibeauty/g);

export const isAiBeautyInternational = window.navigator.userAgent.match(
  /appName\/withInternational/g
);

export const getTextColor = () => {
  return isAiBeauty ? "text-ai_beauty" : "text-zi";
};
export const getBorderColor = () => {
  return isAiBeauty ? "border-ai_beauty" : "border-zi";
};
export const getBgColor = () => {
  return isAiBeauty ? "bg-ai_beauty" : "bg-zi";
};
export const getRefImage = () => {
  return isAiBeauty
    ? "bg-[url('../assets/images/ref2.png')]"
    : "bg-[url('../assets/images/ref.png')]";
};
export const getDownloadImage = () => {
  return isAiBeauty ? "download_ai_beauty.png" : "download.png";
};
export const getReDrawImage = () => {
  return isAiBeauty ? "re_ai_beauty.png" : "re.png";
};
export const getMemberImage = () => {
  return isAiBeauty
    ? "bg-[url('../assets/images/member_dialog2.webp')]"
    : "bg-[url('../assets/images/member_dialog.webp')]";
};
export const getMemberBtnImage = () => {
  return isAiBeauty
    ? "bg-[url('../assets/images/btnbg2.webp')]"
    : "bg-[url('../assets/images/btnbg.webp')]";
};
export const getAutoImage = () => {
  return isAiBeauty
      ? "bg-[url('../assets/images/auto_beauty.png')]"
      : "bg-[url('../assets/images/auto_zi.png')]";
};
export const getAiUserListCheckedImage = () => {
  return isAiBeauty ? "user_sel2.png" : "user_sel.png";
};

export const getSelectPicImage = () => {
  return isAiBeauty ? "select_pic2.png" : "select_pic.png";
};

export const getPicxsImage = () => {
  return "bg-[url('../assets/images/bg_picxs.webp')]";
};


export const log = (
  eventName: string,
  parameters?: any,
  valueToSum?: number
) => {
  const eventNameStr = window.location.pathname.substring(1) + "_" + eventName;
  console.info(eventNameStr, parameters, valueToSum);
  // commonInterface("logEvent", {
  //   eventName: eventNameStr,
  //   valueToSum: valueToSum ? valueToSum : 1,
  //   parameters: parameters ? JSON.stringify(parameters) : "",
  // });
};

export const isAndroid =
  navigator.userAgent.indexOf("Android") > -1 ||
  navigator.userAgent.indexOf("Adr") > -1;
export const appName = () => {
  const regex = /appName\/(\w+)/;
  const match = window.navigator.userAgent.match(regex);

  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const appVersionCode = (): any => {
  const regex = /appVersionCode:(\d+)/;
  const match = window.navigator.userAgent.match(regex);
  if (match) {
    return match[1];
  } else {
    return 0;
  }
};
export const isShowInput = (name: string) => {
  if (name in showConfig) {
    const platform = isAndroid ? "android" : "ios";
    const app = appName();
    const versionCode: any = parseInt(appVersionCode(), 10);
    if (
      app in showConfig[name][platform] &&
      versionCode >= showConfig[name][platform][app]
    ) {
      return true;
    }
  }
  return false;
};
export const getAppLogo = () => {
  const name = appName() || "withInternational";
  return AppConfig[name].logo;
};
export const getAppName = () => {
  const name = appName() || "withInternational";
  return AppConfig[name].name;
};
export const getRemoveObjectImage = () => {
  return isAiBeauty
    ? "bg-[url('../assets/images/changeV2/ro_beauty.png')]"
    : "bg-[url('../assets/images/changeV2/ro_zi.png')]";
};
const padZero = (num: number) => {
  return num < 10 ? `0${num}` : num;
};
export const formatTime = (time: number) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hour = padZero(date.getHours());
  const minute = padZero(date.getMinutes());
  const second = padZero(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};
export const getExpirationDate = () => {
  const nsfw = localStorage.getItem("nsfw");
  if (nsfw) {
    const parsedValue = JSON.parse(nsfw);
    const currentTime = new Date().getTime();
    return parsedValue < currentTime;
  } else {
    return true;
  }
};
export const getFooterTips = () => {
  return (
      "友情提醒：请遵守图片创作规范，禁止提交违法内容，违规内容会被系统拦截，严重者可能被封禁账号，详细规范见《" +
      (isAiBeauty ? "AI美人" : "With") +
      "社区规范》"
  );
};
export const sleep = async () => {
  const random = (Math.random() * 8 + 3) >> 0; // 3 ~ 10秒，之后在请求
  console.info(random + "秒后，发起第一次请求");
  await waitFor(random * 1000);
};