import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: {
    icon: string;
    name: string;
  };
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();
  return value.name !== "" ? (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.选}</span>
        <span>{lang.择}</span>
        <span>{lang.风}</span>
        <span>{lang.格}：</span>
      </div>
      <div className="flex items-center">
        <img
          alt=""
          src={value.icon}
          className="w-3.33 h-3.33 rounded-0.42 mr-0.33"
        />
        {value.name}
      </div>
    </div>
  ) : null;
};

export default Header;
