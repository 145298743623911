export default {
  lang: "zh",
  AI绘画: "AI绘画",
  输入描述语: "输入描述语",
  随机一个: "随机一个",
  清空文本: "清空文本",
  选择风格: "选择风格",
  选择比例: "选择比例",
  上传参考图: "上传参考图",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作: "让AI在参考图基础上，按描述进行创作",
  质量: "质量",
  标准: "标准",
  更多细节: "更多细节",
  描述词相关度: "描述词相关度",
  低: "低",
  中: "中",
  高: "高",
  友情提醒:
    "友情提醒：请遵守图片创作规范，禁止提交违法内容，违规内容会被系统拦截，严重者可能被封禁账号。",
  生成画作: "生成画作",
  "AI绘画发起中...": "AI绘画发起中...",
  "AI绘画解析中...": "AI绘画解析中...",
  "AI绘画生成中...": "AI绘画生成中...",
  无可清空内容: "无可清空内容~",
  请输入描述语: "请输入描述语",
  提示: "提示",
  确认删除该画作: "确认删除该画作？",
  取消: "取消",
  确定: "确定",
  图片举报成功感谢你的反馈: "图片举报成功，感谢你的反馈",
  描: "描",
  述: "述",
  词: "词",
  选: "选",
  择: "择",
  风: "风",
  格: "格",
  比: "比",
  例: "例",
  质: "质",
  量: "量",
  参: "参",
  考: "考",
  图: "图",
  相: "相",
  似: "似",
  度: "度",
  重绘: "重绘",
  删除: "删除",
  下载: "下载",
  喜欢: "喜欢",
  请检查网络: "请检查网络",
  您还暂未开通会员: "您还暂未开通会员",
  立即开通: "立即开通",
  开通会员描述: "畅享无限AI绘画，不限制次数",
  历史记录: "历史记录",
  收藏: "收藏",
  智能替换: "Remove Object",
  请您先在图片上标记想要替换的元素:
    "Please mark the elements you want to replace on the image first～",
  选择替换区域: "Select replacement area",
  快速选择: "Cut everything",
  笔刷: "Brush",
  橡皮擦: "Erase",
  图片分区智能识别中: "Start processing the image",
  开始下载: "Start downloading...",
  "Reference image": "Reference image",
  Optional: "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
