import React, { useEffect, useState } from "react";
import { getBgColor, isAndroid, isVip } from "../utils/utils";

interface IProps {
  text: string;
  disable: boolean;
  submit: Function;
  credits: string;
}

const Footer: React.FC<IProps> = (props) => {
  const { text, submit, disable, credits } = props;
  const [showCredits, setShowCredits] = useState(false);
  useEffect(() => {
    isVip().then((res) => {
      setShowCredits(res);
    });
  }, []);
  return (
    <div
      className={
        "pt-1 px-1.33 " +
        (isAndroid ? "pb-12" : "pb-3.83") +
        " bg-white rounded-t-1.67 shadow-up " +
        (disable ? "opacity-50" : "")
      }
      onClick={() => submit()}
    >
      <div
        className={
          "rounded-2.33 p-1.17 text-1.67 text-center text-white relative " +
          getBgColor()
        }
      >
        {text}
        {showCredits && (
          <div className="absolute right-0 top-0 bg-credits text-0.83 rounded-tr-1.83 rounded-bl-1.33 py-0.33 px-0.83">
            {credits}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
