import React from "react";
import { getLanguageComponent } from "../../utils/language";

interface IProps {
  value: number;
}

enum ratio {
  标准 = 1,
  更多细节 = 2,
}

const Header: React.FC<IProps> = (props) => {
  const { value } = props;
  const lang = getLanguageComponent();

  return value !== 0 ? (
    <div className="result-item">
      <div className="result-item-left">
        <span>{lang.质}</span>
        <span>{lang.量}：</span>
      </div>
      <div className="p-0.67 bg-bg rounded-0.42">
        {value === ratio.标准 ? lang.标准 : lang.更多细节}
      </div>
    </div>
  ) : null;
};

export default Header;
