export default {
  lang: "fr",
  AI绘画: "AiLady", // 应用名称或品牌标识不翻译
  输入描述语: "Entrer l'indication",
  随机一个: "Inspiration",
  清空文本: "Effacer l'indication",
  选择风格: "Choisir un style",
  选择比例: "Choisir un format",
  上传参考图: "Télécharger une image",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作: "L'image que vous sélectionnez sera utilisée comme référence pour le produit final",
  质量: "Qualité",
  标准: "Standard",
  更多细节: "Plus de détails",
  描述词相关度: "Échelle CFG",
  低: "Faible",
  中: "Moyen",
  高: "Élevé",
  友情提醒: "Rappel amical : Veuillez respecter les normes de création d'images et éviter de soumettre un contenu d'image illégal. Le contenu illégal peut être intercepté par le système, et votre compte peut également être suspendu.",
  生成画作: "Générer",
  "AI绘画发起中...": "Analyse en cours...",
  "AI绘画解析中...": "Ajout de détails...",
  "AI绘画生成中...": "En cours de création...",
  无可清空内容: "Rien à effacer...",
  请输入描述语: "Veuillez entrer l'indication d'abord",
  提示: "Avis",
  确认删除该画作: "Êtes-vous sûr de vouloir supprimer cette création maintenant ?",
  取消: "Annuler",
  确定: "Confirmer",
  图片举报成功感谢你的反馈: "Signalement réussi",
  请检查网络: "Veuillez vérifier votre connexion réseau",
  您还暂未开通会员: "Rejoignez Pro",
  立即开通: "Continuer",
  开通会员描述: "Profitez de créativité AI illimitée et d'une expérience de peinture avancée",
  历史记录: "Historique",
  收藏: "Marquer",
  智能替换: "Supprimer l'objet",
  请您先在图片上标记想要替换的元素: "Veuillez marquer d'abord les éléments que vous souhaitez remplacer sur l'image～",
  选择替换区域: "Sélectionner la zone de remplacement",
  快速选择: "Tout couper",
  笔刷: "Pinceau",
  橡皮擦: "Gomme",
  图片分区智能识别中: "Traitement en cours",
  开始下载: "Téléchargement en cours...",
  "Reference image": "Image de Pose",
  Optional: "Optionnel",
  "Image similarity": "Similarité d'image",
  "Higher parameter,closer to original image.": "Paramètre plus élevé, plus proche de l'image originale.",
  "Looks like": "Correspondance de Visage",
  "Pose Matching to a Reference Image": "Correspondance de pose avec une image de référence",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.": "Fournissez juste une photo de référence, et notre technologie reproduira sans peine sa pose dans une nouvelle image.",
  "OK,I got it!": "OK, j'ai compris !",
  "How to make a quality creation": "Comment créer une œuvre de qualité",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.": "Veuillez télécharger une photo avec un visage clair et de face. Une photo de mauvaise qualité entraînera une mauvaise création.",
  Credit: "Crédit",
  Credits: "Crédits",
  Buy: "Acheter",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits": "Vos crédits ne sont pas suffisants pour utiliser cette fonctionnalité. Veuillez visiter le centre d'achat pour acheter plus de crédits",
  "Upgrade to Pro ?": "Passer à Pro ?",
  "Or continue without Pro library": "Ou continuer sans la bibliothèque Pro",
  Upgrade: "Améliorer",
  "The creation may contain NSFW content.": "La création peut contenir du contenu NSFW.",
  "Don't ask again for 30 days": "Ne pas demander à nouveau pendant 30 jours",
  "I understand and wish to proceed": "Je comprends et souhaite continuer",
  "Watch ad to get free trial!": "Regardez une pub pour obtenir un essai gratuit !",
  "Upload photo": "Télécharger une photo",
  Payments: "Paiements",
  "No data available": "Aucune donnée disponible",
  "Subscribe to unlock limit": "Abonnez-vous pour débloquer la limite",
  "Watch ad to create": "Regarder une pub pour créer",
  ClaimSuccess: "C'est fait ! ✅ Nous avons sauvegardé vos crédits pour demain. Assurez-vous de les réclamer avant leur expiration !",
  Magic:"La magie infuse... 🧙‍"
};