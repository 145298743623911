import React from "react";
import { Popup } from "antd-mobile";
import { commonInterface } from "../utils/utils";
import { getLanguageComponent } from "../utils/language";

interface IProps {
  hideDialog: Function;
  aiGraphType: number;
  showAdBtn: boolean;
  adBtnText: string;
  subscribeBtnText: string;
  adEndCallBack: any;
  closePopupADBack?: Function;
}

const lang = getLanguageComponent();
const DialogOpenMember: React.FC<IProps> = (props) => {
  const {
    hideDialog,
    aiGraphType,
    showAdBtn,
    adBtnText,
    subscribeBtnText,
    adEndCallBack,
    closePopupADBack,
  } = props;

  const jumpToRecharge = () => {
    commonInterface("jumpToRecharge");
    hideDialog();
    //跳转到充值页
  };
  window.onGetAdReward = () => {
    console.info("看完广告了");
    adEndCallBack();
  };
  return (
    <Popup
      visible={true}
      onMaskClick={() => {
        hideDialog();
      }}
      onClose={() => {
        hideDialog();
      }}
      showCloseButton={true}
      bodyStyle={{
        borderTopLeftRadius: "1.67rem",
        borderTopRightRadius: "1.67rem",
        backgroundColor: "rgba(246, 247, 249, 1)",
      }}
    >
      <div className="py-2.5 px-1.67 flex flex-col justify-between h-21.5">
        <div className="text-center text-1.5 font-bold">
          {lang["Watch ad to get free trial!"]}
        </div>
        <div>
          <div
            onClick={jumpToRecharge}
            className="bg-white py-1.58 text-center text-255 rounded-2.5 font-bold mb-1.67"
          >
            {subscribeBtnText}
          </div>
          <div
            onClick={() => {
              if (showAdBtn) {
                commonInterface("openScheme", {
                  scheme: `with://com.with.fm/app/rewarded-ad?aiGraphType=${aiGraphType}`,
                });
                hideDialog();
              }
            }}
            className={`bg-white py-1.58 text-center text-84 rounded-2.5 font-bold ${
              showAdBtn ? "" : "opacity-30"
            }`}
          >
            {adBtnText}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default DialogOpenMember;
