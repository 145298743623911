import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  adAvailable,
  creatable,
  createGraphApi,
  detail,
  galleryDetail,
  getDrawConfig,
  crossAppInivtationCode,
} from "../service/api";
import {
  commonInterface,
  getAppName,
  getExpirationDate,
  isShowInput,
  log,
  run,
} from "../utils/utils";
import { useSearchParams } from "react-router-dom";
import "../assets/draw.css";
import DialogBuyAiPoint from "../components/DialogBuyAiPoint";
import Prompt from "../components/Prompt";
import Style from "../components/Style";
import AspectRatio from "../components/AspectRatio";
import UploadImage from "../components/UploadImage";
import Quality from "../components/Quality";
import Relevancy from "../components/Relevancy";
import BtnSubmit from "../components/BtnSubmit";
import { Toast } from "antd-mobile";
import { waitFor } from "../utils/drawUtils";
import LoadingComponent from "../components/LoadingToast";
import PopupAD from "../components/PopupAD";
import DialogOpenMember from "../components/DialogOpenMember";
import { LOCATION } from "../service/config";
import DialogVipWords from "../components/DialogVipWords";
import FaceImage from "../components/FaceImage";
import DialogAfterCancel from "../components/DialogAfterCancel";
import DialogNewApp from "../components/DialogNewApp";

const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const galleryDetailId = params.get("gallery_detail_id");

  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowBuyPoint, setIsShowBuyPoint] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowVipWords, setIsShowVipWords] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [vipWordsDesc, setVipWordsDesc] = useState("");
  const [prompt, setPrompt] = useState("");
  const [style, setStyle] = useState({ id: 0, name: "", vipOnly: false });
  const [inputImage, setInputImage] = useState("");
  const [inputImageWidth, setInputImageWidth] = useState(undefined);
  const [inputImageHeight, setInputImageHeight] = useState(undefined);
  const [inputImageSize, setInputImageSize] = useState(undefined);
  const [aspectRatio, setAspectRatio] = useState(100);
  const [quality, setQuality] = useState(1);
  const [relevancy, setRelevancy] = useState(2);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [subscribeBtnText, setSubscribeBtnText] = useState("");
  const [trimmedPrompt, setTrimmedPrompt] = useState("");
  const [reset, setReset] = useState(false);
  const [adAvailableStatus, setAdAvailableStatus] = useState(0);
  const [continueBtnClick, setContinueBtnClick] = useState(false);
  const [faceImage, setFaceImage] = useState("");
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [similarity, setSimilarity] = useState(0);
  const [defaultSimilarity, setDefaultSimilarity] = useState(0);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [examplePrompt, setExamplePrompt] = useState("");
  const [faceImageSamples, setFaceImageSamples] = useState([]);
  const [isShowDialogAfterCancel, setIsShowDialogAfterCancel] = useState(false);
  const [dialogAfterCancelData, setDialogAfterCancelData] = useState({
    dialogUrl: "",
    activity: "",
  });
  const [invitationCode, setInvitationCode] = useState("");

  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });
  const [closePopupADBack, setClosePopupADBack] = useState(() => {
    return () => {};
  });
  const setDefaultValue = (res: any) => {
    console.info("设置默认值");
    if (res.code === 0) {
      res.data.prompt && setPrompt(res.data.prompt);
      res.data.style && setStyle(res.data.style);
      res.data.inputImage && setInputImage(res.data.inputImage);
      res.data.inputImageWidth && setInputImageWidth(res.data.inputImageWidth);
      res.data.inputImageHeight &&
        setInputImageHeight(res.data.inputImageHeight);
      res.data.inputImageSize && setInputImageSize(res.data.inputImageSize);
      res.data.aspectRatio && setAspectRatio(res.data.aspectRatio);
      res.data.relevancy && setRelevancy(res.data.relevancy);
      res.data.quality && setQuality(res.data.quality);
    } else {
      Toast.show(res.msg);
    }
  };

  const getAdHandler = async () => {
    const res: any = await adAvailable({
      location: LOCATION.ENTER_DRAW_PAGE,
    });
    if (isShowInput("interAd") && res.data.status === 1) {
      commonInterface("openScheme", {
        scheme: `with://com.with.fm/app/inter-ad?aiGraphType=1`,
      });
    }
  };

  useEffect(() => {
    crossAppInivtationCode().then((res:any) => {
      if (res.code === 0 && res.data !== null) {
        setInvitationCode(res.data.value);
      }
    });

    if (galleryDetailId) {
      galleryDetail({ id: galleryDetailId }).then(async (res: any) => {
        setDefaultValue(res);
      });
    } else if (detailId) {
      detail({ id: detailId }).then((res: any) => setDefaultValue(res));
    }
    getAdHandler();
    getDrawConfig({ type: 1 }).then((res: any) => {
      if (res.code === 0) {
        setSuggestedPrompts(res.data.suggestedPrompts);
        setExamplePrompt(res.data.examplePrompt);
        setFaceImageSamples(res.data.faceImageSamples);
        setSimilarity(res.data.defaultSimilarity);
        setDefaultSimilarity(res.data.defaultSimilarity);
      }
    });
    log("page_load_success");
  }, []);

  const checkForm = () => {
    let result = true;
    const errMsgArr = [];
    if (prompt.trim() === "") {
      errMsgArr.push(props.lang.请输入描述语);
      result = false;
    }
    if (errMsgArr.length > 0) {
      Toast.show(errMsgArr.join("，"));
    }
    return result;
  };

  const createHandler = async () => {
    const createResult: any = await createGraphApi({
      aspectRatio,
      inputImage,
      quality,
      prompt,
      relevancy,
      inputImageWidth,
      inputImageHeight,
      inputImageSize,
      similarity,
      faceImage,
      styleId: style.id,
      galleryId: galleryDetailId,
    });

    setBtnDisable(false);
    if (createResult.code === 0) {
      setIsShowLoading(true);
      commonInterface("setDrawingId", {
        drawingId: createResult.data.id,
      });
      const random = (Math.random() * 8 + 3) >> 0; // 3 ~ 10秒，之后在请求
      console.info(random + "秒后，发起第一次请求");
      await waitFor(random * 1000);
      const result = await run(createResult.data.id);
      console.info(result);

      commonInterface("setDrawingId", { drawingId: "" });
      if (result["code"] === 0) {
        setIsShowLoading(false);
        commonInterface("refreshImageHistory");
        console.info(getExpirationDate());

        const scheme = `with://com.with.fm/app/h5?url=${encodeURIComponent(
          `${window.location.origin}/draw_result?from=draw&isFullScreen=true&detail_id=${result["data"]["id"]}`
        )}`;
        commonInterface("openScheme", { scheme });
        setReset(true);
      } else {
        Toast.show({
          content: result["msg"],
          afterClose: () => setIsShowLoading(false),
        });
      }
    } else {
      Toast.show(createResult["msg"]);
    }
  };
  const submitHandler = async () => {
    log("submit_click");
    if (checkForm()) {
      if (btnDisable) return;
      setBtnDisable(true);
      try {
        const response: any = await creatable({ type: 1, prompt });
        if (response.code === 0) {
          if (response.data.creatable) {
            createHandler();
          } else {
            setDialogAfterCancelData(response.data.dialogAfterCancel);

            setAdAvailableStatus(response.data.adAvailableStatus);
            if (response.data?.vipWordsBlocked) {
              setIsShowVipWords(true);
              setTrimmedPrompt(response.data.trimmedPrompt);
              setVipWordsDesc(response.data.vipWordsDesc);
              if (response.data.adAvailableStatus === 1) {
              } else {
              }
            } else {
              setAdEndCallback(() => createHandler);
              if (response.data.adAvailableStatus !== 0) {
                // 支持广告或VIP情况
                setShowAdBtn(response.data.adAvailableStatus === 1);
                setSubscribeBtnText(
                  response.data.isVip
                    ? "Get more credits"
                    : "Subscribe to unlock limit"
                );
                setIsShowPopupAD(true);
              } else {
                if (response.data.isVip) {
                  setIsShowBuyPoint(true); // VIP情况下的买点弹窗
                } else {
                  setIsShowMember(true); // 非VIP情况
                }
              }
            }

            setBtnDisable(false);
          }
        }
      } catch (e) {
        setBtnDisable(false);
      }
    }
  };
  useEffect(() => {
    if (prompt && continueBtnClick) {
      console.info("useEffect");
      createHandler();
      setContinueBtnClick(false);
    }
  }, [prompt, continueBtnClick]);
  return (
    <div className="bg-white flex flex-col justify-between h-full pt-55">
      <Header
        title={getAppName()}
        backFunc={() => commonInterface("closePage")}
        rightText={isShowInput("history") ? props.lang.历史记录 : ""}
        rightFunc={() => {
          log("history_click");
          commonInterface("openScheme", {
            scheme: `with://com.with.fm/app/ai/draw/history?title=${encodeURIComponent(
              props.lang.历史记录
            )}&name=draw&group=0&type=1`,
          });
        }}
      ></Header>
      <div className="p-1.33 overflow-auto">
        <Prompt
          initValue={prompt}
          setValue={(value: string) => setPrompt(value)}
          prompts={suggestedPrompts}
          examplePrompt={examplePrompt}
        />
        <Style
          initValue={style}
          setValue={(value: any) => setStyle(value)}
          type={1}
        />
        <UploadImage
          initValue={inputImage}
          setValue={(value: string) => setInputImage(value)}
          setInputImageWidth={(value: any) => setInputImageWidth(value)}
          setInputImageHeight={(value: any) => setInputImageHeight(value)}
          setInputImageSize={(value: any) => setInputImageSize(value)}
          similarity={similarity}
          defaultSimilarity={defaultSimilarity}
          setSimilarity={(value: any) => setSimilarity(value)}
        />
        <FaceImage
          initValue={faceImage}
          setValue={(value: any) => setFaceImage(value)}
          faceImageSamples={faceImageSamples}
        />
        <AspectRatio
          initValue={aspectRatio}
          setValue={(value: any) => setAspectRatio(value)}
          reset={reset}
        />
        <Quality
          initValue={quality}
          setValue={(value: number) => setQuality(value)}
          reset={reset}
        />
        <Relevancy
          initValue={relevancy}
          setValue={(value: number) => setRelevancy(value)}
          reset={reset}
        />
      </div>
      <BtnSubmit
        text={props.lang.生成画作}
        disable={btnDisable}
        submit={submitHandler}
        credits={"1 " + props.lang.Credit}
      />
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => {
          setIsShowMember(false);
        }}
        cancelHandler={() => {
          setIsShowMember(false);
          if (dialogAfterCancelData.dialogUrl) {
            Toast.show({
              icon: "loading",
              content: props.lang.Magic,
            });
            setTimeout(() => {
              setIsShowDialogAfterCancel(true);
            }, 2000);
          }
        }}
      ></DialogOpenMember>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={
            subscribeBtnText || props.lang["Subscribe to unlock limit"]
          }
          adBtnText={props.lang["Watch ad to create"]}
          adEndCallBack={adEndCallback}
          closePopupADBack={closePopupADBack}
        />
      )}
      <DialogBuyAiPoint
        isShow={isShowBuyPoint}
        lang={props.lang}
        hideDialog={() => {
          setIsShowBuyPoint(false);
        }}
        cancelHandler={() => {
          setIsShowBuyPoint(false);
          if (dialogAfterCancelData.dialogUrl) {
            setIsShowDialogAfterCancel(true);
          }
        }}
      />
      {(invitationCode !== "") && (<DialogNewApp
        isShow={true}
        invitationCode={invitationCode}
        lang={props.lang}
        hideDialog={() => {
          setInvitationCode("");
        }}
      />
      )}
      {isShowVipWords && (
        <DialogVipWords
          isShow={isShowVipWords}
          lang={props.lang}
          text={vipWordsDesc}
          hideDialog={() => {
            setIsShowVipWords(false);
            if (dialogAfterCancelData.dialogUrl) {
              setIsShowDialogAfterCancel(true);
            }
          }}
          aiGraphType={1}
          adAvailableStatus={adAvailableStatus}
          continueCallback={() => {
            console.info("continueCallback");
            setPrompt(trimmedPrompt);
            setContinueBtnClick(true);
          }}
        />
      )}
      <LoadingComponent isShowLoading={isShowLoading} />
      <DialogAfterCancel
        isShow={isShowDialogAfterCancel}
        hideDialog={() => setIsShowDialogAfterCancel(false)}
        dialogAfterCancelData={dialogAfterCancelData}
      />
    </div>
  );
};
export default App;
