import React, { useState } from "react";
import { commonInterface } from "../../utils/utils";
import { nativeUploadHandler } from "../../utils/drawUtils";
import DialogOpenMember from "../DialogOpenMember";
import { creatable, predictAuto } from "../../service/api";
import { Toast } from "antd-mobile";
import DialogBuyAiPoint from "../DialogBuyAiPoint";
import { getLanguageComponent } from "../../utils/language";
import BtnSubmit from "../BtnSubmit";

interface IProps {}

const FooterUploadImage: React.FC<IProps> = (props) => {
  const {} = props;
  const lang = getLanguageComponent();
  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowBuyPoint, setIsShowBuyPoint] = useState(false);

  window.uploadImageResult = async (
    url: string,
    width: number,
    height: number,
    size: number
  ) => {
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: lang.图片分区智能识别中,
    });
    console.info();
    predictAuto({ inputImage: url }).then((res: any) => {
      Toast.clear();
      if (res.code === 0) {
        const scheme =
          `with://com.with.fm/app/h5?url=` +
          encodeURIComponent(
            `${
              window.location.origin
            }/change_form?from=draw&isFullScreen=true&inputImage=${encodeURIComponent(
              url
            )}&colorImage=${encodeURIComponent(
              res.data.mask
            )}&inputImageWidth=${width}&inputImageHeight=${height}&inputImageSize=${size}`
          );
        console.info("scheme", scheme);
        commonInterface("openScheme", { scheme });
      } else {
        Toast.show(res.desc);
      }
    });
  };

  const submitHandler = async () => {
    const response: any = await creatable({ type: 5 });
    if (response.code === 0) {
      if (response.data.creatable) {
        nativeUploadHandler();
      } else {
        if (response.data.isVip) {
          setIsShowBuyPoint(true); // VIP情况下的买点弹窗
        } else {
          setIsShowMember(true); // 非VIP情况
        }
      }
    }
  };
  return (
    <>
      <BtnSubmit
        text={lang["Upload photo"]}
        disable={false}
        submit={submitHandler}
        credits={"PRO"}
      />
      <DialogBuyAiPoint
        isShow={isShowBuyPoint}
        lang={lang}
        hideDialog={() => setIsShowBuyPoint(false)}
        cancelHandler={() => {
          setIsShowBuyPoint(false);
        }}
      />
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </>
  );
};
export default FooterUploadImage;
