import React, { useEffect, useState } from "react";
import { getAppLogo, getAppName, isVip, log } from "../../utils/utils";

import DialogOpenMember from "../DialogOpenMember";

interface IProps {}

const Footer: React.FC<IProps> = (props) => {
  const {} = props;
  const [isShow, setIsShow] = useState(true);
  const [isShowMember, setIsShowMember] = useState(false);
  const [vip, setVip] = useState(false);
  useEffect(() => {
    isVip().then((res) => {
      setVip(res);
    });
  }, []);

  return isShow && !vip ? (
    <div
      onClick={() => {
        setIsShowMember(true);
        log("remove_logo_image_click");
      }}
      className="absolute left-1.33 top-8.33 rounded-0.67 border-0.08 border-dashed border-white border-opacity-50 bg-black bg-opacity-50 p-0.67 flex items-center z-50"
    >
      <img className="h-2 w-2 mr-0.83 rounded-0.67" src={getAppLogo()} />
      <div className="text-white font-bold text-1.33">{getAppName()}</div>
      <img
        className="w-1.33 h-1.33 right-f0.665 top-f0.665 absolute"
        src={require("../../assets/images/del_logo.png")}
      />
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </div>
  ) : null;
};
export default Footer;
