import React, { useEffect, useState } from "react";
import {
  getAutoImage,
  getBorderColor,
  getTextColor,
  log,
} from "../utils/utils";
import { getLanguageComponent } from "../utils/language";
import { incentiveAD_jumpToRecharge } from "../utils/drawUtils";
import { LOCATION } from "../service/config";
import PopupAD from "./PopupAD";
import DialogOpenMember from "./DialogOpenMember";

interface IProps {
  initValue: number;
  setValue: Function;
  reset: boolean;
}

export enum ratio {
  正方形 = 1,
  横图 = 2,
  竖图 = 3,
  Auto = 100,
}

const AspectRatio: React.FC<IProps> = (props) => {
  const { initValue, setValue, reset } = props;
  const lang = getLanguageComponent();
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });

  const [locks, setLocks] = useState({
    1: false,
    2: false,
    3: false,
  });
  useEffect(() => {
    if (reset) {
      console.log("页面已跳转,AspectRatio,状态重置");
      setLocks({ 1: false, 2: false, 3: false });
    }
  }, [reset]);
  const changeHandler = async (
    index: ratio.正方形 | ratio.横图 | ratio.竖图
  ) => {
    if (locks[index]) {
      setValue(index);
    } else {
      await incentiveAD_jumpToRecharge(
        () => callback(index),
        setShowAdBtn,
        setIsShowPopupAD,
        setAdEndCallback,
        setIsShowMember,
        LOCATION.ADVANCED_DRAW_SETTING
      );
    }
  };

  const callback = (n: ratio) => {
    setValue(n);
    setLocks((prevLocks) => ({ ...prevLocks, [n]: true }));
  };
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/2.png')] "></div>
        {lang.选择比例}
        <span className="text-red text-251">*</span>
      </div>
      <div className="item-body-rounded">
        <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
          <div
            onClick={() => {
              log("aspectRatio_click", { value: "100" });
              setValue(ratio.Auto);
            }}
            className={
              "option " +
              (initValue === ratio.Auto
                ? "option-active " + getTextColor()
                : "")
            }
          >
            <div
              className={
                "w-2 h-2 bg-contain mr-0.33 " +
                (initValue === ratio.Auto
                  ? getAutoImage()
                  : "bg-[url('../assets/images/auto.png')]")
              }
            ></div>
            Auto
          </div>
          <div className="option-space">
            {initValue === ratio.Auto || initValue === ratio.正方形 ? "" : "|"}
          </div>
          <div
            onClick={() => {
              log("aspectRatio_click", { value: "1" });
              setValue(ratio.正方形);
            }}
            className={
              "option " +
              (initValue === ratio.正方形
                ? "option-active " + getTextColor()
                : "")
            }
          >
            <div
              className={
                "ratio-icon w-1.67 h-1.67 " +
                (initValue === ratio.正方形 ? getBorderColor() : "")
              }
            ></div>
            {lang.正方形}
          </div>
          <div className="option-space">
            {initValue === ratio.正方形 || initValue === ratio.竖图 ? "" : "|"}
          </div>
          <div
            onClick={() => {
              log("aspectRatio_click", { value: "3" });
              setValue(ratio.竖图);
            }}
            className={
              "option " +
              (initValue === ratio.竖图
                ? "option-active " + getTextColor()
                : "")
            }
          >
            <div
              className={
                "ratio-icon w-1.5 h-1.83 " +
                (initValue === ratio.竖图 ? getBorderColor() : "")
              }
            ></div>
            {lang.竖图}
          </div>
          <div className="option-space">
            {initValue === ratio.横图 || initValue === ratio.竖图 ? "" : "|"}
          </div>
          <div
            onClick={() => {
              log("aspectRatio_click", { value: "2" });
              setValue(ratio.横图);
            }}
            className={
              "option " +
              (initValue === ratio.横图
                ? "option-active " + getTextColor()
                : "")
            }
          >
            <div
              className={
                "ratio-icon w-1.83 h-1.5 " +
                (initValue === ratio.横图 ? getBorderColor() : "")
              }
            ></div>
            {lang.横图}
          </div>
        </div>
      </div>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to unlock limit"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </>
  );
};

export default AspectRatio;
