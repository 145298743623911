import React from "react";
import { getBgColor } from "../../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  paintType: string;
}

const config: {
  [key: string]: {
    title: string;
    subtitle: string;
    body: string;
    video: string;
  };
} = {
  select: {
    title: "How to use the cut everything",
    subtitle: "Add and subtract areas",
    body: "After image cutting successfully, you can click to select an area, click again to deselect the area",
    video: require("../../assets/video/edit_cut_every_thing.gif"),
  },
  draw: {
    title: "How to use the brush tool",
    subtitle: "Brush areas",
    body: "Mask areas by Brush button. Adjust Brush size to modify your selection area.",
    video: require("../../assets/video/edit_brush.gif"),
  },
  clear: {
    title: "How to use the erase tool",
    subtitle: "Erase areas",
    body: "Remove area by Erase button. Adjust erase size to modify your selection area.",
    video: require("../../assets/video/edit_erase.gif"),
  },
};
const Footer: React.FC<IProps> = (props) => {
  const { isShow, hideDialog, paintType } = props;

  const dontShowHandler = () => {
    hideDialog(false);
  };
  return isShow ? (
    <div className="fixed w-full left-0 top-0 h-full bg-black bg-opacity-50 flex items-center justify-center z-100">
      <div className="bg-white text-31 w-90 rounded-1.67 p-1.67 flex flex-col items-center relative">
        <div className="text-1.67 font-bold">{config[paintType]["title"]}</div>
        <img src={config[paintType]["video"]} className="my-1.67" />
        <div className="text-1.5 mb-0.42 font-bold">
          {config[paintType]["subtitle"]}
        </div>
        <div className="text-34 opacity-80 text-1.25  text-center">
          {config[paintType]["body"]}
        </div>
        <div
          onClick={dontShowHandler}
          className={`rounded-2.33 p-1.17 mt-1.67 self-stretch text-center ${getBgColor()}`}
        >
          <div className="text-white font-bold text-1.67">OK,I got it!</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Footer;
