import React from "react";
import { Dialog } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";
import { commonInterface, getBgColor } from "../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  lang: any;
  cancelHandler: Function;
}

const lang = getLanguageComponent();
const DialogOpenMember: React.FC<IProps> = (props) => {
  const { isShow, hideDialog, cancelHandler } = props;
  const jumpToRecharge = () => {
    commonInterface("jumpToRecharge");
    hideDialog();
    //跳转到充值页
  };
  return (
    <Dialog
      visible={isShow}
      closeOnMaskClick={true}
      bodyClassName="bg-transparent"
      content={
        <div className="p-1.67 bg-white rounded-1.67">
          <div className="font-bold text-1.33 text-center mb-1">
            {lang.Credits}
          </div>
          <div className="text-card mb-1.67">
            {
              lang[
                "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits"
              ]
            }
          </div>
          <div className="flex justify-between items-center">
            <div
              onClick={() => {
                cancelHandler();
              }}
              className="flex justify-center items-center h-3.33 mr-1.08 flex-1 rounded-1.88 border-0.04 border-180 font-bold text-card"
            >
              {lang.取消}
            </div>
            <div
              className={`${getBgColor()} flex justify-center items-center h-3.33 rounded-1.88 text-white font-bold flex-1`}
              onClick={jumpToRecharge}
            >
              {lang.Buy}
            </div>
          </div>
        </div>
      }
      closeOnAction
      onClose={() => hideDialog()}
    ></Dialog>
  );
};

export default DialogOpenMember;
