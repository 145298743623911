import { getOssUrl } from "./config";
import { ossAuthApi } from "../service/api";
import axios from "axios";

const crypto = require("crypto-js");

const getSignature = (policy: any, accessKeySecret: any) => {
  return crypto.enc.Base64.stringify(crypto.HmacSHA1(policy, accessKeySecret));
};
const getPolicyBase64 = () => {
  let date = new Date();
  date.setHours(date.getHours() + 1);
  let srcT = date.toISOString();
  const policyText = {
    expiration: srcT,
    conditions: [["content-length-range", 0, 100 * 1024 * 1024]],
  };
  return crypto.enc.Base64.stringify(
    crypto.enc.Utf8.parse(JSON.stringify(policyText))
  );
};

export const Upload = async (file: any) => {
  const res: any = await ossAuthApi({
    fileType: "jpeg",
  });
  const key = `${"h5/ai/" || ""}${file.name}`;
  const policy = getPolicyBase64();
  const signature = getSignature(policy, res.data.accessKeySecret);

  const formData = new FormData();

  formData.append("key", key);
  formData.append("name", file.name);
  formData.append("policy", policy);
  formData.append("OSSAccessKeyId", res.data.accessKeyId);
  formData.append("signature", signature);
  formData.append("success_action_status", "200");
  formData.append("x-oss-security-token", res.data.securityToken);
  formData.append("file", file);
  return new Promise((resolve, reject) => {
    axios
      .post(getOssUrl(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((Response) => {
        resolve({ code: 0, url: getOssUrl() + "/" + key });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const UploadBase64 = async (base64File: string) => {
  const res: any = await ossAuthApi({
    fileType: "jpeg",
  });

  const key = `${"h5/ai/" || ""}${res.data.fileName}`;
  const policy = getPolicyBase64();
  const signature = getSignature(policy, res.data.accessKeySecret);
  const formData = new FormData();
  formData.append("key", key);
  formData.append("name", res.data.fileName);
  formData.append("policy", policy);
  formData.append("OSSAccessKeyId", res.data.accessKeyId);
  formData.append("signature", signature);
  formData.append("success_action_status", "200");
  formData.append("x-oss-security-token", res.data.securityToken);
  const byteCharacters = atob(base64File.split(",")[1]);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const blob = new Blob([new Uint8Array(byteArrays)]);

  formData.append("file", blob);
  return new Promise((resolve, reject) => {
    axios
        .post(getOssUrl(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((Response) => {
          resolve({ code: 0, url: getOssUrl() + "/" + key });
        })
        .catch(function (error) {
          reject(error);
        });
  });
};