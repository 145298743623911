import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Toast } from "antd-mobile";
import { action, adAvailable, detail } from "../service/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  commonInterface,
  getAppName,
  isShowInput,
  isVip,
  log,
} from "../utils/utils";
import "../assets/draw.css";
import { collect, like, LOCATION } from "../service/config";
import PopupAD from "../components/PopupAD";
import Nsfw from "../components/Nsfw";
import Prompt from "../components/drawResult/Prompt";
import Style from "../components/drawResult/Style";
import AspectRatio from "../components/drawResult/AspectRatio";
import InputImage from "../components/drawResult/InputImage";
import Quality from "../components/drawResult/Quality";
import Relevancy from "../components/drawResult/Relevancy";
import Footer from "../components/drawResult/Footer";
import BigImage from "../components/drawResult/BigImage";
import WaterMark from "../components/drawResult/WaterMark";
import Report from "../components/drawResult/Report";
import Preview from "../components/drawResult/Preview";
import FaceImage from "../components/drawResult/FaceImage";

const LIKE_ROTATE = ["rotate(-30deg)", "rotate(0)", "rotate(30deg)"];

let likeTimer: any = null;

const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const navigate = useNavigate();
  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [adAvailableStatus, setAdAvailableStatus] = useState(0);
  const [isShowBigImage, setIsShowBigImage] = useState(false);
  const [hd, setHd] = useState(2);

  const [prompt, setPrompt] = useState("");
  const [style, setStyle] = useState({ icon: "", name: "" });
  const [inputImage, setInputImage] = useState("");
  const [faceImage, setFaceImage] = useState("");
  const [aspectRatio, setAspectRatio] = useState(100);
  const [quality, setQuality] = useState(1);
  const [relevancy, setRelevancy] = useState(2);
  const [likeBannedText, setLikeBannedText] = useState("");
  const [downloadBannedText, setDownloadBannedText] = useState("");

  const [reportBanned, setReportBanned] = useState(false);
  const [liked, setLiked] = useState(false);
  const [nsfw, setNsfw] = useState(false);

  const [likeStyle, setLikeStyle] = useState({
    opacity: 0,
    left: -60,
    top: -60,
    transform: "-30deg",
    zIndex: -1,
    transition: "1s all",
  });
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const from: any = params.get("from");

  const [x1Image, setX1Image] = useState("");
  const [x2Image, setX2Image] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });
  useEffect(() => {
    detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
      if (res.code === 0) {
        res.data.prompt && setPrompt(res.data.prompt);
        res.data.style && setStyle(res.data.style);
        res.data.inputImage && setInputImage(res.data.inputImage);
        res.data.faceImage && setFaceImage(res.data.faceImage);
        res.data.aspectRatio && setAspectRatio(res.data.aspectRatio);
        res.data.relevancy && setRelevancy(res.data.relevancy);
        res.data.quality && setQuality(res.data.quality);
        res.data.liked && setLiked(res.data.liked);
        res.data.nsfw && setNsfw(res.data.nsfw);
        res.data.likeBannedText && setLikeBannedText(res.data.likeBannedText);
        res.data.downloadBannedText &&
          setDownloadBannedText(res.data.downloadBannedText);
        res.data.reportBanned && setReportBanned(res.data.reportBanned);

        setX2Image(JSON.parse(res.data.content).images[0].url);
      } else {
        Toast.show(res.msg);
      }
    });
  }, []);

  const downloadHandler = async (event: any) => {
    log("download_click");
    if (reportBanned) {
      Toast.show(downloadBannedText);
      return;
    }
    const vip = await isVip();

    action({
      actionName: "download",
      graphId: detailId,
    }).then(() => {});
    if (vip) {
      downloadImage();
    } else {
      const res: any = await adAvailable({ location: LOCATION.DOWNLOAD_IMAGE });
      if (isShowInput("ad") && res.data.status !== 0) {
        setAdAvailableStatus(res.data.status);
        setIsShowPopupAD(true);
        setAdEndCallback(() => downloadImage);
      } else {
        setIsShowMember(true);
      }
    }
  };
  const downloadImage = () => {
    console.info("直接执行");
    commonInterface("downloadImage", {
      url: hd === 2 ? x2Image : x1Image,
    });
  };
  const likeHandler = (type: boolean, event?: any) => {
    log("like_click");
    event.stopPropagation();
    if (likeBannedText) {
      Toast.show(likeBannedText);
      return;
    }
    if (type) {
      action({
        graphId: detailId,
        actionName: "like",
      }).then((res: any) => {
        if (res.code === 0) {
          const rotate = LIKE_ROTATE[(Math.random() * 3) >> 0];
          setLikeStyle({
            opacity: 1,
            left: event.clientX - 40,
            top: event.clientY - 40,
            transform: rotate,
            zIndex: 100,
            transition: "0s all",
          });
          setLiked(true);

          if (likeTimer) {
            clearTimeout(likeTimer);
          }
          likeTimer = setTimeout(() => {
            setLikeStyle({
              ...likeStyle,
              left: event.clientX - 40,
              top: event.clientY - 40,
              opacity: 0,
              transform: rotate,
              zIndex: -1,
              transition: "0.2s all",
            });
          }, 1000);
          commonInterface("refreshImageHistory");
        }
      });
    } else {
      if (liked) {
        action({
          graphId: detailId,
          actionName: "cancel_like",
        }).then((res: any) => {
          if (res.code === 0) {
            setLiked(false);
            commonInterface("refreshImageHistory");
          }
        });
      } else {
        action({
          graphId: detailId,
          actionName: "like",
        }).then((res: any) => {
          if (res.code === 0) {
            setLiked(true);
            commonInterface("refreshImageHistory");
          }
        });
      }
    }
  };

  return (
    <div className="bg-bg flex flex-col justify-between h-full">
      <div className="overflow-auto">
        <Preview
          likeHandler={likeHandler}
          setIsShowBigImage={setIsShowBigImage}
          x1Image={x1Image}
          x2Image={x2Image}
          setX1Image={setX1Image}
          hd={hd}
          setHd={(value: number) => {
            setHd(value);
          }}
          setPreviewImage={(value: string) => {
            setPreviewImage(value);
          }}
          reportBanned={reportBanned}
        />
        <div className="p-1.33 pt-0.33">
          <Prompt value={prompt} />
          <Style value={style} />
          <AspectRatio value={aspectRatio} />
          <InputImage value={inputImage} />
          <FaceImage value={faceImage} />
          <Quality value={quality} />
          <Relevancy value={relevancy} />
        </div>
      </div>
      <Footer
        liked={liked}
        detailId={detailId}
        downloadHandler={downloadHandler}
        likeHandler={likeHandler}
      />
      <div
        className="absolute top-4.5 left-1.33 w-3 h-3 bg-[url('../assets/images/back.png')] bg-2 bg-center bg-no-repeat z-50"
        onClick={() => {
          commonInterface("closePage");
        }}
      ></div>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={adAvailableStatus === 1}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to download"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>

      <BigImage
        isShow={isShowBigImage}
        liked={liked}
        downloadHandler={downloadHandler}
        likeHandler={likeHandler}
        setIsShowBigImage={setIsShowBigImage}
        previewImage={previewImage}
      />
      <img
        className="absolute w-80 h-80"
        style={likeStyle}
        src={isShowInput("collect") ? collect : like}
      />
      {!reportBanned && <WaterMark />}
      <Nsfw isShow={nsfw} hideDialog={() => setNsfw(false)} />
    </div>
  );
};

export default App;
