import React from "react";
import { getLanguageComponent } from "../utils/language";
import { creatableGift } from "../service/api";
import { Toast } from "antd-mobile";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  dialogAfterCancelData: { dialogUrl: string; activity: string };
}

const lang = getLanguageComponent();
const DialogAfterCancel: React.FC<IProps> = (props) => {
  const { isShow, hideDialog, dialogAfterCancelData } = props;
  const sendActivity = () => {
    creatableGift({ activity: dialogAfterCancelData.activity }).then(
      (res: any) => {
        if (res.code === 0) {
          Toast.show(lang.ClaimSuccess);
        }
      }
    );
  };
  return isShow ? (
    <div
      className="fixed w-full left-0 top-0 bg-black bg-opacity-60 h-full flex items-center justify-center z-50"
      onClick={() => hideDialog()}
    >
      <img
        onClick={sendActivity}
        width="80%"
        src={dialogAfterCancelData.dialogUrl}
      />
    </div>
  ) : null;
};

export default DialogAfterCancel;
