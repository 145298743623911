import React from "react";
import { Dialog, Toast } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";
import { getPicxsImage } from "../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  lang: any;
  invitationCode: string;
}

const lang = getLanguageComponent();
const DialogNewApp: React.FC<IProps> = (props) => {
  const {isShow, hideDialog, invitationCode} = props;

  return (
    <Dialog      
      visible={isShow}
      closeOnMaskClick={true}
      bodyClassName="bg-transparent"
      content={
        <div
          className={
            getPicxsImage() +
            " w-22.58 h-26.67 bg-contain bg-no-repeat flex flex-col relative"
          }
        >
          <div className="font-extrabold text-1.33 mb-1 mt-2 ml-1">
          Move to PicXs &<br/>
          Get Extras Credits!
          </div>
          <div className="ml-1">
          Switch to PicXs on Google Play! Use your invite code in the settings page to bind and transfer all benefits, plus get 100 free credits. 
          
          </div>
          <div className="text-green text-center m-0.67 font-bold text-3">
            {invitationCode}
          </div>
          <div className="text-center">
          Search "<strong>PicXs</strong>" & Upgrade now!
          </div>

          <div className="m-1 mb-1.67">
            <div
              className={`bg-black flex justify-center items-center h-3.33 rounded-1.88 text-white font-bold flex-1`}
              onClick={() => {
                hideDialog();
              }}
            >
              {"Switch to PicXs"}
            </div>
          </div>
        </div>
      }
      closeOnAction
      onClose={() => hideDialog()}
    ></Dialog>
  );
};

export default DialogNewApp;
