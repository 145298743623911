import React, { useEffect, useRef, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { commonInterface, query, run, sleep } from "../utils/utils";
import { Toast } from "antd-mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/draw.css";
import Prompt from "../components/change/Prompt";
import { createGraphApi, detail } from "../service/api";
import Style from "../components/change/Style";
import Loading from "../components/Loading";
import PaintView from "../components/change/PaintView";
import PaintBox from "../components/change/PaintBox";
import { getMaskBase64 } from "../utils/inPaintUtils";
import { UploadBase64 } from "../service/upload";
import Help from "../components/change/Help";
import BtnSubmit from "../components/BtnSubmit";

let createId = 0;
const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const inputImageUrl = params.get("inputImage") || "";
  const colorImageUrl = params.get("colorImage") || "";
  const inputImageWidthUrl = params.get("inputImageWidth") || 0;
  const inputImageHeightUrl = params.get("inputImageHeight") || 0;
  const inputImageSizeUrl = params.get("inputImageSize") || 0;
  const from = params.get("from");
  const [showLoading, setShowLoading] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [inputImage, setInputImage] = useState(inputImageUrl);
  const [colorImage, setColorImage] = useState(colorImageUrl);
  const [inputImageWidth, setInputImageWidth] = useState(inputImageWidthUrl);
  const [inputImageHeight, setInputImageHeight] = useState(inputImageHeightUrl);
  const [inputImageSize, setInputImageSize] = useState(inputImageSizeUrl);

  const [paintType, setPaintType] = useState("select");
  const [paintNum, setPaintNum] = useState(50);
  const [prompt, setPrompt] = useState("");
  const [mask, setMask] = useState("");
  const [preview, setPreview] = useState("");
  const [style, setStyle] = useState({ id: 0, name: "" });

  const [log, setLog] = useState("");
  const [tips, setTips] = useState([]);
  const [titles, setTitles] = useState([]);
  const [estimatedSeconds, setEstimatedSeconds] = useState(0);
  const paintCanvas = useRef<HTMLCanvasElement>(null);
  const maskCanvas = useRef<HTMLCanvasElement>(null);
  const [removeObject, setRemoveObject] = useState(false);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [expand, setExpand] = useState(6);
  const [isShowHelp, setIsShowHelp] = useState(false);
  useEffect(() => {
    if (detailId) {
      detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
        if (res.code === 0) {
          setInputImage(res.data.inputImage);
          setInputImageWidth(res.data.inputImageWidth);
          setInputImageHeight(res.data.inputImageHeight);
          setInputImageSize(res.data.inputImageSize);

          setMask(res.data.mask);
          setPreview(res.data.maskPreview);
          setStyle(res.data.style);
          setPrompt(res.data.prompt);
        } else {
          Toast.show(res.msg);
        }
      });
    }
    commonInterface("enableGesture", { enable: false });
  }, []);

  const createHandler = async () => {
    const createResult: any = await createGraphApi({
      inputImage,
      styleId: removeObject ? undefined : style.id,
      prompt,
      mask,
      maskPreview: preview,
      inputImageWidth,
      inputImageHeight,
      inputImageSize,
      removeObject,
      type: 5,
    });
    console.info(createResult);
    if (createResult.code === 0) {
      console.info(createResult);
      createId = createResult.data.id;
      commonInterface("setDrawingId", {
        drawingId: createId,
      });
      await sleep(); // 3 ~ 10秒，之后在请求
      await firstQueryHandler();
    } else {
      setShowLoading(false);
      setBtnDisable(false);
      Toast.show(createResult.desc || createResult.msg);
    }
  };

  const firstQueryHandler = async () => {
    try {
      const responseFirst: any = await query(createId);
      setLoadingValue(responseFirst); //loading组件需要的文字
      await normalQueryHandler(); //正常轮训
    } catch (e) {
      setShowLoading(false);
      setBtnDisable(false);
      commonInterface("setDrawingId", { drawingId: "" });
    }
  };
  const normalQueryHandler = async () => {
    const result = await run(createId);

    setLoadingValue(null);

    commonInterface("setDrawingId", { drawingId: "" });
    if (result["code"] === 0) {
      commonInterface("refreshImageHistory");
      const scheme = `with://com.with.fm/app/h5?url=${encodeURIComponent(
        window.location.origin +
          `/change_result?from=draw&isFullScreen=true&detail_id=${createId}`
      )}`;
      console.info("scheme", scheme);
      commonInterface("openScheme", { scheme });
      createId = 0;
    } else {
      Toast.show(result["msg"]);
    }
  };
  const check = async () => {
    if (prompt.trim() === "" && !removeObject) {
      Toast.show(props.lang.请输入描述语);
      return false;
    }

    if (undoStack.length === 0 && redoStack.length === 0) {
      Toast.show(props.lang.请您先在图片上标记想要替换的元素);
      return false;
    }

    return true;
  };
  const setLoadingValue = (responseFirst: any) => {
    if (responseFirst) {
      setTitles(responseFirst.data.titles);
      setTips(responseFirst.data.tips);
      setEstimatedSeconds(responseFirst.data.estimatedSeconds);
    } else {
      setTips([]);
      setShowLoading(false);
      setBtnDisable(false);
    }
  };
  const buildMaskFromCanvas = async () => {
    if (paintCanvas.current && maskCanvas.current) {
      const maskBase64 = getMaskBase64(paintCanvas, maskCanvas, expand);
      if (maskBase64) {
        const res: any = await UploadBase64(maskBase64);
        setMask(res.url);
      } else {
        Toast.show(props.lang.请您先在图片上标记想要替换的元素);
        setBtnDisable(false);
      }
    }
  };

  useEffect(() => {
    if (mask) {
      createHandler();
    }
  }, [mask]);
  const submitHandler = async () => {
    if (!(await check())) return;
    if (btnDisable) return;
    setShowLoading(true);
    setBtnDisable(true);
    await buildMaskFromCanvas();
  };
  return (
    <div
      className={`flex flex-col justify-between h-full ${
        inputImage ? "bg-white " : "bg-bg pt-1.67"
      } `}
    >
      <div
        className="fixed top-4.5 left-1.33 bg-[url('../assets/images/change/re_select.png')] w-2 h-2 bg-contain z-100"
        onClick={() => {
          commonInterface("closePage");
        }}
      ></div>
      <div
        className="fixed top-4.5 right-1.33 bg-[url('../assets/images/changeV2/help.png')] w-2 h-2 bg-contain z-98"
        onClick={() => {
          setIsShowHelp(true);
        }}
      ></div>
      <PaintView
        inputImage={inputImage}
        colorImage={colorImage}
        paintType={paintType}
        paintNum={paintNum}
        paintCanvas={paintCanvas}
        undoStack={undoStack}
        redoStack={redoStack}
        setUndoStack={(value: any) => setUndoStack(value)}
        setRedoStack={(value: any) => setRedoStack(value)}
      />
      <div className="flex-1 p-1.33 overflow-auto pb-2.5">
        <PaintBox
          paintType={paintType}
          setPaintType={(value: string) => setPaintType(value)}
          paintNum={paintNum}
          setPaintNum={(value: number) => setPaintNum(value)}
        />
        <Prompt
          initValue={prompt}
          setValue={(value: string) => setPrompt(value)}
          removeObject={removeObject}
          setRemoveObject={(value: boolean) => setRemoveObject(value)}
        />
        {!removeObject && (
          <Style initValue={style} setValue={(value: any) => setStyle(value)} />
        )}
      </div>
      <BtnSubmit
        text={"Create"}
        disable={btnDisable}
        submit={submitHandler}
        credits={"5 Credits"}
      />
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
      {showLoading && (
        <Loading
          log={log}
          tips={tips}
          titles={titles}
          isShow={showLoading}
          estimatedSeconds={estimatedSeconds}
        />
      )}
      <canvas
        className="opacity-0 absolute z-f1 bottom-f9999 left-f9999"
        ref={maskCanvas}
      />
      <Help
        isShow={isShowHelp}
        paintType={paintType}
        hideDialog={() => setIsShowHelp(false)}
      />
    </div>
  );
};

export default App;
