import React from "react";
import { Dialog } from "antd-mobile";
import { getLanguageComponent } from "../utils/language";
import { commonInterface, getBgColor } from "../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  lang: any;
  text: string;
  continueCallback: Function;
  adAvailableStatus: number;
  aiGraphType: number;
}

const lang = getLanguageComponent();
const DialogOpenMember: React.FC<IProps> = (props) => {
  const {
    isShow,
    hideDialog,
    text,
    continueCallback,
    aiGraphType,
    adAvailableStatus,
  } = props;
  const jumpToRecharge = () => {
    commonInterface("jumpToRecharge");
    hideDialog();
    //跳转到充值页
  };
  window.onGetAdReward = () => {
    console.info("看完广告了");
    continueCallback();
  };
  const continueHandler = () => {
    commonInterface("openScheme", {
      scheme: `with://com.with.fm/app/rewarded-ad?aiGraphType=${aiGraphType}`,
    });
    hideDialog();
  };
  return (
    <Dialog
      visible={isShow}
      closeOnMaskClick={true}
      bodyClassName="bg-transparent"
      content={
        <div className="p-1.67 bg-white rounded-1.67">
          <div className="font-bold text-1.33 text-center mb-1">
            {lang["Upgrade to Pro ?"]}
          </div>
          <div className="text-card mb-1.67">
            The following words are for Pro only: <strong>{text}</strong>
            <br />
            <br /> Upgrade to Pro now and unlock our VIP prompt library! <br />
            {adAvailableStatus === 1
              ? lang["Or continue without Pro library"]
              : ""}
          </div>
          <div className="">
            <div
              onClick={() => {
                adAvailableStatus === 1 ? continueHandler() : hideDialog();
              }}
              className="flex justify-center items-center text-1 h-3.33 mb-1.08 flex-1 rounded-1.88 border-0.04 border-180 font-bold text-card"
            >
              {adAvailableStatus === 1
                ? "Continue without Pro library"
                : "Cancel"}
            </div>
            <div
              className={`${getBgColor()} flex justify-center items-center h-3.33 rounded-1.88 text-white font-bold flex-1`}
              onClick={jumpToRecharge}
            >
              {lang.Upgrade}
            </div>
          </div>
        </div>
      }
      closeOnAction
      onClose={() => hideDialog()}
    ></Dialog>
  );
};

export default DialogOpenMember;
