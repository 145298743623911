export default {
  lang: "en",
  AI绘画: "AI 描画",
  输入描述语: "説明を入力",
  随机一个: "ランダムな選択",
  清空文本: "テキストをクリア",
  选择风格: "スタイルを選択",
  选择比例: "比率を選択",
  上传参考图: "参考画像をアップロード",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "AI による説明に基づいて参考画像上で創作させる",
  质量: "品質",
  标准: "標準",
  更多细节: "詳細情報",
  描述词相关度: "記述子の関連性",
  低: "低い",
  中: "中程度",
  高: "高い",
  友情提醒:
    "友好なリマインダー: 画像の作成基準を遵守し、違法なコンテンツの提出を禁止してください。違法なコンテンツはシステムによってインターセプトされ、深刻な場合、アカウントが禁止される可能性があります。詳細は「",
  社区规范: " コミュニティ基準」をご覧ください",
  生成画作: "アートワークを生成",
  "AI绘画发起中...": "AI 描画開始中...",
  "AI绘画解析中...": "AI 描画分析中...",
  "AI绘画生成中...": "AI 描画生成中...",
  无可清空内容: "クリアする内容がありません~",
  请输入描述语: "説明を入力してください",
  提示: "プロンプト",
  确认删除该画作: "この絵を削除してもよろしいですか？",
  取消: "キャンセル",
  确定: "OK",
  图片举报成功感谢你的反馈:
    "画像の報告が成功しました。フィードバックいただきありがとうございます",
  描: "",
  述: "",
  词: "記述的な言葉",
  选: "",
  择: "",
  风: "",
  格: "選択したスタイル",
  比: "",
  例: "選択した比率",
  质: "",
  量: "品質",
  参: "",
  考: "",
  图: "参考画像",
  相: "",
  似: "",
  度: "類似度",
  重绘: "再描画",
  删除: "削除",
  下载: "ダウンロード",
  喜欢: "いいね",
  请检查网络: "ネットワークを確認してください",
  您还暂未开通会员: "まだ会員登録をしていません",
  立即开通: "今すぐオープン",
  开通会员描述:
    "1日<span style='color:rgba(255, 181, 77, 1)'>0.01元</span>と低く、回数制限なしで無限のAI絵画を楽しむ",
  历史记录: "歴史の記録",
  收藏:"コレクション",
  "Reference image": "Reference image",
  "Optional": "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Looks like",
  "Pose Matching to a Reference Image": "Pose Matching to a Reference Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"

};
