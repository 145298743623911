import React, { useEffect } from "react";
import { Toast } from "antd-mobile";
import { getLanguageComponent } from "../utils/language"; // 替换为你使用的 Toast 库

interface IProps {
  isShowLoading: boolean;
}

let timer: any;
const LoadingComponent: React.FC<IProps> = (props) => {
  const { isShowLoading } = props;
  const loadingText: Array<string> = [
    "AI绘画发起中...",
    "AI绘画解析中...",
    "AI绘画生成中...",
  ];

  const lang = getLanguageComponent();

  const showLoadingText = (index: number) => {
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: lang[loadingText[index]],
    });

    timer = setTimeout(() => {
      index = index < loadingText.length - 1 ? index + 1 : index;
      console.info(index);
      showLoadingText(index);
      if (index === loadingText.length - 1) {
        clearTimeout(timer);
      }
    }, 5000);
  };

  useEffect(() => {
    if (isShowLoading) {
      showLoadingText(0);
    } else {
      Toast.clear()
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isShowLoading]);

  return <div>{/* 在这里添加你想要展示的加载动画或样式 */}</div>;
};

export default LoadingComponent;
