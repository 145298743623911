import React, { useEffect, useRef, useState } from "react";
import { LOCATION } from "../../service/config";
import PopupAD from "../PopupAD";
import DialogOpenMember from "../DialogOpenMember";
import { incentiveAD } from "../../utils/drawUtils";
import { getAppName } from "../../utils/utils";

interface IProps {
  likeHandler: Function;
  setIsShowBigImage: Function;
  x1Image: string;
  x2Image: string;
  setX1Image: Function;
  hd: number;
  setHd: Function;
  setPreviewImage: Function;
  reportBanned: boolean;
}

let clickTimer: any = null;
const scale = 1.6;
const Header: React.FC<IProps> = (props) => {
  const {
    likeHandler,
    setIsShowBigImage,
    setX1Image,
    x1Image,
    x2Image,
    hd,
    setHd,
    setPreviewImage,
    reportBanned,
  } = props;
  const canvas = useRef<HTMLCanvasElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [x2Lock, setX2Lock] = useState(false);
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });
  const previewHandleClick = (event: any) => {
    if (!clickTimer) {
      clickTimer = setTimeout(function () {
        setIsShowBigImage(true);
        clickTimer = null;
      }, 300); // 设置延迟时间
    } else {
      clearTimeout(clickTimer);
      clickTimer = null;
      likeHandler(true, event);
    }
  };
  const onloadHandler = (event: any) => {
    console.info(getAppName());
    if (canvas.current) {
      const ctx = canvas.current.getContext("2d");
      if (!ctx) return;
      setHeight(event.target.offsetHeight);
      setWidth(event.target.offsetWidth);
      canvas.current.width = event.target.naturalWidth / scale;
      canvas.current.height = event.target.naturalHeight / scale;
      ctx.drawImage(
        event.target,
        0,
        0,
        event.target.naturalWidth,
        event.target.naturalHeight,
        0,
        0,
        canvas.current.width,
        canvas.current.height
      );
      setX1Image(canvas.current.toDataURL());
    }
  };

  useEffect(() => {
    setPreviewImage(hd === 1 ? x1Image : x2Image);
  }, [hd, x1Image, x2Image, x2Lock]);
  return (
    <div className="relative">
      <img
        onClick={previewHandleClick}
        alt=""
        crossOrigin="anonymous"
        onLoad={onloadHandler}
        src={x2Image}
        className={`relative ${hd === 2 ? "z-20" : ""}`}
      />

      <canvas
        onClick={previewHandleClick}
        ref={canvas}
        style={{
          width,
          height,
        }}
        className={`absolute top-0 ${hd === 1 ? "z-20" : ""}`}
      />

      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to unlock limit"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </div>
  );
};

export default Header;
