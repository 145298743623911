import React, { useEffect, useState } from "react";
import { Tabs } from "antd-mobile";
import { getBorderColor, getTextColor, isAiBeauty, log } from "../utils/utils";
import {getStyleBoxWidth, incentiveAD, incentiveAD_jumpToRecharge} from "../utils/drawUtils";
import { getStyleListApi, styleTabs } from "../service/api";
import { getLanguageComponent } from "../utils/language";
import { LOCATION } from "../service/config";
import PopupAD from "./PopupAD";
import DialogOpenMember from "./DialogOpenMember";

const styleCacheData: any = [];

interface StyleType {
  id: number;
  name: string;
  vipOnly: boolean;
}

interface IProps {
  initValue: StyleType;
  setValue: Function;
  type: number;
}

const Style: React.FC<IProps> = (props) => {
  const { initValue, setValue, type } = props;
  const [tabList, setTabList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const lang = getLanguageComponent();
  const [showAdBtn, setShowAdBtn] = useState(false);
  const [isShowPopupAD, setIsShowPopupAD] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [adEndCallback, setAdEndCallback] = useState(() => {
    return () => {};
  });
  const getStyleData = (pageInfo: string, tab?: number): any => {
    return getStyleListApi({ tab, pageInfo, type }).then((res: any) => {
      if (res.data.pageInfo) {
        return getStyleData(res.data.pageInfo, tab).then((resNextData: any) => {
          return [...res.data.list, ...resNextData];
        });
      } else {
        return res.data.list;
      }
    });
  };
  const getTabListData = () => {
    return styleTabs({ type }).then((res: any) => {
      if (res.code === 0) {
        setTabList(res.data.list);
        if (res.data.list.length > 0) {
          return getStyleData("", res.data.list[0].id);
        } else {
          return getStyleData("");
        }
      }
    });
  };
  const changeStyleTabHandler = (key: any) => {
    const styleData = styleCacheData[key];
    log("tab_click", { id: key });
    if (styleData) {
      setStyleList(styleData);
      setValue(styleData[0]);
    } else {
      getStyleData("", key).then((res: any) => {
        setStyleList(res);
        setValue(res[0]);
        styleCacheData[key] = res;
      });
    }
  };
  useEffect(() => {
    getTabListData().then((res) => {
      setStyleList(res);
      log("page_success");
    });
  }, []);

  useEffect(() => {
    if (initValue && initValue.id === 0 && styleList.length > 0) {
      setValue(styleList[0]);
    } else {
      setValue(initValue);
    }
  }, [initValue, styleList]);
  const changeHandler = async (item: StyleType) => {
    if (item.vipOnly) {
      await incentiveAD_jumpToRecharge(
        () => callback(item),
        setShowAdBtn,
        setIsShowPopupAD,
        setAdEndCallback,
        setIsShowMember,
        LOCATION.ADVANCED_DRAW_SETTING
      );
    } else {
      setValue(item);
    }
  };

  const callback = (item: StyleType) => {
    setValue(item);
  };
  return (
    <>
      <div className="item-title">
        <div className="icon bg-[url('../assets/images/icon/1.png')]"></div>
        {lang.选择风格}
        <span className="text-red text-251">*</span>
      </div>
      <div
        className={
          "rounded-1.67 pl-1.33 pr-1.33 mb-2.5 border-0.08 border-242 " +
          (tabList.length > 0 ? "pt-1.33" : "")
        }
      >
        <div>
          <Tabs
            className={"my-tabs " + (isAiBeauty ? "ai_beauty" : "with")}
            activeLineMode="fixed"
            defaultActiveKey="0"
            style={{
              "--fixed-active-line-width": "20px", // 自定义宽度
            }}
            onChange={changeStyleTabHandler}
          >
            {tabList.map((item: any) => {
              return <Tabs.Tab title={item.name} key={item.id} />;
            })}
          </Tabs>
        </div>
        <div
          className="item-body-rounded-style"
          style={{ width: getStyleBoxWidth(styleList.length) }}
        >
          {styleList.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  "w-6.67 relative " +
                  (index === styleList.length - 1 ||
                  index === styleList.length - 2
                    ? ""
                    : " mr-1")
                }
                onClick={() => {
                  log("style_click", {
                    id: item.id.toString(),
                    name: item.name,
                  });
                  changeHandler(item);
                }}
              >
                <div className="w-6.67 h-6.67 relative rounded-1.33 overflow-hidden">
                  <img alt="" src={item.icon} />
                  {initValue.id === item.id ? (
                    <div
                      className={
                        "absolute  rounded-1.33 top-0 w-full h-full border-2px " +
                        getBorderColor()
                      }
                    ></div>
                  ) : null}
                </div>
                <div
                  className={
                    "text-center pt-0.33 w-6.67 overflow-hidden white-space-nowrap overflow-ellipsis " +
                    (initValue.id === item.id ? getTextColor() : "")
                  }
                >
                  {item.name}
                </div>
                {item.vipOnly && <div className="sz"></div>}
              </div>
            );
          })}
        </div>
      </div>
      {isShowPopupAD && (
        <PopupAD
          showAdBtn={showAdBtn}
          aiGraphType={1}
          hideDialog={() => setIsShowPopupAD(false)}
          subscribeBtnText={"Subscribe to unlock limit"}
          adBtnText="Watch ad to unlock limit"
          adEndCallBack={adEndCallback}
        />
      )}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
    </>
  );
};

export default Style;
