import React, { useEffect, useState } from "react";
import { commonInterface, getTextColor } from "../utils/utils";
import { Swiper } from "antd-mobile";
import "../assets/draw.css";
import { redrawSamples } from "../service/api";
import FooterUploadImageV2 from "../components/change/FooterUploadImageV2";

let createId = 0;
const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const [samples, setSamples] = useState<{ image: string }[]>([]);

  useEffect(() => {
    redrawSamples({ type: 5 }).then((res: any) => {
      if (res.code === 0 && res.data.list.length > 0) {
        setSamples(res.data.list);
      }
    });
  }, []);

  return (
    <div className={`flex flex-col justify-between h-full bg-bg pt-1.67 `}>
      <div className="flex-1 overflow-auto px-1.67">
        {samples.length > 0 && (
          <Swiper
            className="photo-swiper mb-2.5"
            autoplayInterval={5000}
            loop
            autoplay
          >
            {samples.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <img className="w-full rounded-1.33" src={item.image}></img>
                </Swiper.Item>
              );
            })}
          </Swiper>
        )}
        <div
          className={"text-center text-1.33 " + getTextColor()}
          onClick={() => {
            const scheme = `with://com.with.fm/app/ai/draw/history?title=${encodeURIComponent(
              props.lang.历史记录
            )}&name=change&group=0&type=5`;
            commonInterface("openScheme", { scheme });
          }}
        >
          {props.lang.历史记录}
        </div>
      </div>
      <FooterUploadImageV2 />
    </div>
  );
};

export default App;
