export default {
  lang: "en",
  AI绘画: "AiLady",
  输入描述语: "Input Prompt",
  随机一个: "Inspiration",
  清空文本: "Clear prompt",
  选择风格: "Choose Style",
  选择比例: "Aspect Ratio",
  上传参考图: "Upload Image",
  正方形: "1:1",
  竖图: "9:16",
  横图: "4:3",
  让AI在参考图基础上按描述进行创作:
    "The image you select will be used as a reference for the final output",
  质量: "Quality",
  标准: "Standard",
  更多细节: "More details",
  描述词相关度: "CFG Scale",
  低: "Low",
  中: "Medium",
  高: "High",
  友情提醒:
    "Friendly reminder: Please comply with image creation standards and prohibit submitting illegal image content. Illegal content may be intercepted by the system, and your account may also be banned.",
  生成画作: "Generate",
  "AI绘画发起中...": "Start Analysing...",
  "AI绘画解析中...": "Add more details...",
  "AI绘画生成中...": "Generating...",
  无可清空内容: "Nothing to clear...",
  请输入描述语: "Please input prompt first",
  提示: "Notice",
  确认删除该画作: "Are you sure you want to delete it now?",
  取消: "Cancel",
  确定: "Ok",
  图片举报成功感谢你的反馈: "Reported successfully",
  描: "",
  述: "",
  词: "Prompt",
  选: "",
  择: "",
  风: "",
  格: "Style",
  比: "",
  例: "Ratio",
  质: "",
  量: "Quality",
  参: "",
  考: "",
  图: "Image",
  相: "",
  似: "",
  度: "CFG",
  重绘: "Recreate",
  删除: "Delete",
  下载: "Download",
  喜欢: "Like",
  请检查网络: "Please check the network",
  您还暂未开通会员: "Join Pro",
  立即开通: "Continue",
  开通会员描述:
    "Enjoy unlimited AI creativity & an advanced painting experience",
  历史记录: "History",
  收藏: "Mark",
  智能替换: "Remove Object",
  请您先在图片上标记想要替换的元素:
    "Please mark the elements you want to replace on the image first～",
  选择替换区域: "Select replacement area",
  快速选择: "Cut everything",
  笔刷: "Brush",
  橡皮擦: "Erase",
  图片分区智能识别中: "Start processing",
  开始下载: "Start downloading...",
  "Reference image": "Pose Image",
  Optional: "Optional",
  "Image similarity": "Image similarity",
  "Higher parameter,closer to original image.":
    "Higher parameter,closer to original image.",
  "Looks like": "Face Match",
  "Pose Matching to a Reference Image": "Pose Matching to a Pose Image",
  "Simply provides a reference photo, and our technology effortlessly replicates its pose in a fresh image.":
    "Simply provides a Pose photo, and our technology effortlessly replicates its pose in a fresh image.",
  "OK,I got it!": "OK,I got it!",
  "How to make a quality creation": "How to make a quality creation",
  "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.":
    "Please upload photo with clear, frontal face. Poor quality photo will lead to bad creation.",
  Credit: "Credit",
  Credits: "Credits",
  Buy: "Buy",
  "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits":
    "Your credits are not sufficient to use this feature. Please visit the purchase center to buy more credits",
  "Upgrade to Pro ?": "Upgrade to Pro ?",
  "Or continue without Pro library": "Or continue without Pro library",
  Upgrade: "Upgrade",
  "The creation may contain NSFW content.":
    "The creation may contain NSFW content.",
  "Don't ask again for 30 days": "Don't ask again for 30 days",
  "I understand and wish to proceed": "I understand and wish to proceed",
  "Watch ad to get free trial!": "Watch ad to get free trial!",
  "Upload photo": "Upload photo",
  Payments: "Payments",
  "No data available":"No data available",
  "Subscribe to unlock limit":"Subscribe to unlock limit",
  "Watch ad to create":"Watch ad to create",
  ClaimSuccess:"All set! ✅ We've saved your credits for tomorrow. Make sure to claim them before they're gone!",
  Magic:"Magic's brewing... 🧙‍"
};
