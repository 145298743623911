import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Dialog, Toast } from "antd-mobile";
import { action, detail, remove } from "../service/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  commonInterface,
  getDownloadImage,
  getReDrawImage,
  isAndroid,
  isVip,
} from "../utils/utils";
import "../assets/draw.css";

const App: React.FC<{ lang: Record<string, any> }> = (props) => {
  const navigate = useNavigate();
  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowBigImage, setIsShowBigImage] = useState(false);

  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const from: any = params.get("from");
  const [data, setData] = useState({
    prompt: "",
    style: { name: "", icon: "" },
    downloadBannedText: "",
    reportBanned: false,
    content:
      '{"type":2,"images":[{"url":"","originSize":0,"width":0,"height":0,"duration":0}]}',
  });

  useEffect(() => {
    detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        Toast.show(res.msg);
      }
    });
  }, []);

  const downloadHandler = async (event: any) => {
    event.stopPropagation();
    if (data.reportBanned) {
      Toast.show(data.downloadBannedText);
      return;
    }

    const vip = await isVip();
    action({
      actionName: "download",
      graphId: detailId,
    }).then(() => {});
    if (vip) {
      Toast.show(props.lang.开始下载);
      commonInterface("downloadImage", {
        url: JSON.parse(data.content).images[0].url,
      });
    } else {
      setIsShowMember(true);
    }
  };

  const deleteHandler = async () => {
    const result = await Dialog.confirm({
      bodyClassName: "delete-dialog",
      title: props.lang.提示,
      content: props.lang.确认删除该画作,
      cancelText: props.lang.取消,
      confirmText: props.lang.确定
    });
    if (result) {
      remove({ id: detailId }).then((res: any) => {
        if (res.code === 0) {
          commonInterface("refreshImageHistory");

          commonInterface("closePage");
        } else {
          Toast.show(res.msg);
        }
      });
    }
  };

  const reportHandler = () => {
    action({
      actionName: "report",
      graphId: detailId,
    }).then((res: any) => {
      if (res.code === 0) {
        Toast.show({
          duration: 0,
          maskClickable: false,
          content: props.lang.图片举报成功感谢你的反馈,
        });
        commonInterface("refreshImageHistory");
        setTimeout(() => {
          commonInterface("closePage");
        }, 3000);
      } else {
        Toast.show(res.msg);
      }
    });
  };

  return (
    <div className="bg-bg flex flex-col justify-between h-full">
      <div className="overflow-auto">
        {JSON.parse(data.content).images[0].url && (
          <>
            <div className="relative flex justify-center items-center">
              <img
                className="h-full w-full object-contain"
                onClick={() => setIsShowBigImage(true)}
                src={JSON.parse(data.content).images[0].url}
              />
            </div>
            <div className="p-1.33 pt-0.33">
              {data.prompt && (
                <div className="result-item">
                  <div className="result-item-left">
                    <span>{props.lang.描}</span>
                    <span>{props.lang.述}</span>
                    <span>{props.lang.词}：</span>
                  </div>
                  <div className="flex-1 break-all">{data.prompt}</div>
                </div>
              )}
              {data.style ? (
                <div className="result-item">
                  <div className="result-item-left">
                    <span>{props.lang.选}</span>
                    <span>{props.lang.择}</span>
                    <span>{props.lang.风}</span>
                    <span>{props.lang.格}：</span>
                  </div>
                  <div className="flex items-center">
                    <img
                      alt=""
                      src={data.style.icon}
                      className="w-3.33 h-3.33 rounded-0.42 mr-0.33"
                    />
                    {data.style.name}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      <div
        className={
          "pt-1 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up z-40"
        }
      >
        <div className="flex justify-around p-0.42 rounded-0.67">
          {from && (
            <>
              <div
                onClick={() => {
                  if (from) {
                    commonInterface("closePage");
                  } else {
                    navigate("/change_v2?detail_id=" + detailId, {
                      replace: true,
                    });
                  }
                }}
                className={"btn-item"}
              >
                <img
                  alt=""
                  className="w-1.67 h-1.67 mr-0.33"
                  src={require("../assets/images/" + getReDrawImage())}
                />
                {props.lang.重绘}
              </div>
              <div className="w-0.08 text-266">|</div>
            </>
          )}

          <div onClick={() => deleteHandler()} className={"btn-item"}>
            <img
              alt=""
              className="w-1.67 h-1.67 mr-0.33"
              src={require("../assets/images/del.png")}
            />
            {props.lang.删除}
          </div>
          <div className="w-0.08 text-266">|</div>
          <div
            onClick={(event) => downloadHandler(event)}
            className={"btn-item"}
          >
            <img
              alt=""
              className="w-1.67 h-1.67 mr-0.33"
              src={require("../assets/images/" + getDownloadImage())}
            />
            {props.lang.下载}
          </div>
        </div>
      </div>

      <div
        className="absolute top-4.5 left-1.33 w-3 h-3 bg-[url('../assets/images/back.png')] bg-2 bg-center bg-no-repeat"
        onClick={() => {
          commonInterface("closePage");
        }}
      ></div>
      {!data.reportBanned ? (
        <div
          className="absolute top-4.5 right-1.33 w-3 h-3 bg-[url('../assets/images/report.png')] bg-2 bg-center bg-no-repeat"
          onClick={reportHandler}
        ></div>
      ) : null}

      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
        cancelHandler={() => {
          setIsShowMember(false);
        }}
      ></DialogOpenMember>
      {isShowBigImage ? (
        <div
          onClick={() => setIsShowBigImage(false)}
          className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-black z-50"
        >
          <img
            className="h-full w-full object-contain"
            src={JSON.parse(data.content).images[0].url}
          />
          <div className="absolute bottom-4.5 left-50 flex justify-between ml-f2.5">
            <div
              onClick={downloadHandler}
              className="bg-[url('../assets/images/download2.png')] w-5 h-5 bg-contain bg-no-repeat bg-100  "
            ></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default App;
