export const baseUrl = "https://dev-api.hwith.top/";
export const OSSURL = "https://with-live.oss-cn-beijing.aliyuncs.com";
export const getOssUrl = () => {
  const host = window.location.host;
  if (host === "ai.hwith.cn") {
    return "https://with-oss.oss-cn-beijing.aliyuncs.com";
  } else if (host === "ai.us.hwith.top" || host === "ai.venusai.art") {
    return "https://with-us.oss-us-west-1.aliyuncs.com";
  } else {
    return "https://with-live.oss-cn-beijing.aliyuncs.com";
  }
};

export interface AppConfigType {
  [key: string]: { logo: string; name: string };
}

export const AppConfig: AppConfigType = {
  withInternational: {
    logo: "https://with-oss.oss-cn-beijing.aliyuncs.com/logo_101.png",
    name: "AiLady",
  },
  aigraceInternational: {
    logo: "https://with-us.oss-us-west-1.aliyuncs.com/logo/AiGrace.png",
    name: "AiGrace",
  },
  venusInternational: {
    logo: "https://with-us.oss-us-west-1.aliyuncs.com/logo/VenusAI.png",
    name: "VenusAI",
  },
};

export const like = require("../assets/images/like.png");
export const like1 = require("../assets/images/like1.png");
export const like2 = require("../assets/images/like2.png");
export const like11 = "bg-[url('../assets/images/like11.png')]";
export const like22 = "bg-[url('../assets/images/like22.png')]";
export const collect = require("../assets/images/collect.png");
export const collect1 = require("../assets/images/collect1.png");
export const collect2 = require("../assets/images/collect2.png");
export const collect11 = "bg-[url('../assets/images/collect11.png')]";
export const collect22 = "bg-[url('../assets/images/collect22.png')]";

export const showConfig: any = {
  history: {
    android: {
      with: 219,
      aibeauty: 9,
      withInternational: 230,
      aigraceInternational: 0,
      venusInternational: 0,
    },
    ios: {
      with: 99,
      withInternational: 230,
      aigraceInternational: 0,
      venusInternational: 0,
    },
  },
  uploadAvatar: {
    android: {
      with: 219,
      aibeauty: 9,
      withInternational: 232,
      aigraceInternational: 0,
      venusInternational: 0,
    },
    ios: {
      with: 99,
      withInternational: 232,
      aigraceInternational: 0,
      venusInternational: 0,
    },
  },
  ad: {
    android: {
      withInternational: 232,
      aigraceInternational: 1,
    },
    ios: {
      withInternational: 230,
      aigraceInternational: 1,
    },
  },
  collect: {
    android: {
      withInternational: 231,
      aigraceInternational: 0,
      venusInternational: 0,
    },
    ios: {
      withInternational: 231,
      aigraceInternational: 0,
      venusInternational: 0,
    },
  },
  interAd: {
    android: {
      withInternational: 0,
      aigraceInternational: 7,
    },
    ios: {
      withInternational: 0,
      aigraceInternational: 7,
    },
  },
};

export enum LOCATION {
  LAUNCH_SCREEN = 1,
  DRAW_IMAGE = 2,
  REDRAW_IMAGE = 3,
  DOWNLOAD_IMAGE = 4,
  HOME_BOTTOM_BANNER = 5,
  HOME_IMAGE_DOWNLOAD = 6,
  ENTER_DRAW_PAGE,
  HD = 8,
  REMOVE_WATER_MARK = 9,
  ADVANCED_DRAW_SETTING = 11,
}
